import { Component, input, model, output } from '@angular/core';
import { SKWInventoryLine } from '../../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../../pipes/storekeeper-window-translate.pipe';
import { StorekeeperButtonComponent } from '../../storekeeper-button/storekeeper-button.component';
import { StorekeeperInputComponent } from '../../storekeeper-input/storekeeper-input.component';

@Component({
  selector: 'iu-storekeeper-inventory-line',
  standalone: true,
  imports: [StorekeeperInputComponent, StorekeeperButtonComponent, SKWTranslatePipe],
  templateUrl: './storekeeper-inventory-line.component.html',
  styleUrl: './storekeeper-inventory-line.component.scss'
})
export class StorekeeperInventoryLineComponent {
  complete = input.required<boolean>();

  data = model.required<Partial<SKWInventoryLine>>();

  remove = output<MouseEvent>();
  add = output<MouseEvent>();
  cancel = output<MouseEvent>();

  updateValue(key: keyof SKWInventoryLine, value: string | number) {
    this.data.update((d) => ({ ...d, [key]: value }));
  }
}
