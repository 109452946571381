import { Component, inject, model } from '@angular/core';
import { SKWTranslatePipe } from '@iupics-components/specific/window/storekeeper-window-ui/pipes/storekeeper-window-translate.pipe';
import { SKWTransferService } from '@iupics-components/specific/window/storekeeper-window-ui/services/storekeeper-window-transfer.service';
import { SKWTransferLineFormData } from '../../../models/storekeeper-window.model';
import { StorekeeperButtonComponent } from '../../storekeeper-button/storekeeper-button.component';
import { StorekeeperInputComponent } from '../../storekeeper-input/storekeeper-input.component';
import { StorekeeperWindowInputScanComponent } from '../../storekeeper-window-input-scan/storekeeper-window-input-scan.component';
import { StorekeeperWindowPanelComponent } from '../../storekeeper-window-panel/storekeeper-window-panel.component';

@Component({
  selector: 'iu-storekeeper-window-pallet-panel',
  standalone: true,
  imports: [
    StorekeeperWindowPanelComponent,
    SKWTranslatePipe,
    StorekeeperWindowInputScanComponent,
    StorekeeperInputComponent,
    StorekeeperButtonComponent
  ],
  templateUrl: './storekeeper-transfer-convert-conditioning.component.html',
  styleUrl: './storekeeper-transfer-convert-conditioning.component.scss'
})
export class StorekeeperTransferConvertConditioningComponent {
  #SKWTransferService = inject(SKWTransferService);

  inputData = model<SKWTransferLineFormData>(undefined);

  togglePalletPanel(state?: boolean) {
    this.#SKWTransferService.isConvertingToConditioning.update((v) => state ?? !v);
  }

  cancel(event: MouseEvent) {
    this.togglePalletPanel(false);
  }

  convert(event: MouseEvent) {
    this.#SKWTransferService.saveCondTransfer(this.inputData()?.palette?.id ?? 0);
  }
}
