<iu-storekeeper-input
  [isScanInput]="true"
  [ngClass]="{ search: isSearch() }"
  [label]="'Scan' | SKWTranslate"
  [type]="'text'"
  [value]="scanData()"
  (onReadings)="onReadings($event)"
  [placeholder]="(isSearch() ? 'ScanPlaceholder' : 'ScanFullPlaceholder') | SKWTranslate"
  #input
/>
