<div class="content">
  <iu-storekeeper-window-input-scan [inventory]="true" (inventoryChange)="changeCurrentLine($event)" (validation)="save()" />

  @for (line of lines(); track line) {
    <iu-storekeeper-inventory-line
      [complete]="true"
      [data]="line"
      (dataChange)="changeLine($index, $event)"
      (remove)="removeLine($index, $event)"
    />
  }

  <iu-storekeeper-inventory-line
    [complete]="false"
    [data]="currentLine()"
    (dataChange)="changeCurrentLineQty($event)"
    (add)="addCurrentLine()"
    (cancel)="resetCurrentLine()"
    #lastLine
  />
</div>

<iu-storekeeper-navbar-button
  [icon]="'icon-circle-check'"
  [label]="'Confirm' | SKWTranslate"
  [type]="'confirm'"
  (onClick)="save($event)"
/>
