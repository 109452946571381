import { CdkVirtualScrollableElement } from '@angular/cdk/scrolling';
import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, OnInit, viewChild } from '@angular/core';
import { map, tap } from 'rxjs';
import { SKWLineStatus, SKWStateActionType } from '../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../pipes/storekeeper-window-translate.pipe';
import { SKWScannerService } from '../../services/storekeeper-scanner.service';
import { SKWContextService } from '../../services/storekeeper-window-context.service';
import { SKWMessageService, SKWMessageType } from '../../services/storekeeper-window-message.service';
import { SKWNavigationService } from '../../services/storekeeper-window-navigation.service';
import { SKWTransferService } from '../../services/storekeeper-window-transfer.service';
import { SKWDataService } from '../../services/strokeeper-window-data.service';
import { StorekeeperButtonComponent } from '../storekeeper-button/storekeeper-button.component';
import { StorekeeperEmptyDataComponent } from '../storekeeper-empty-data/storekeeper-empty-data.component';
import { StorekeeperInputComponent } from '../storekeeper-input/storekeeper-input.component';
import { StorekeeperNavbarButtonComponent } from '../storekeeper-navbar/storekeeper-navbar-button/storekeeper-navbar-button.component';
import { StorekeeperNavbarComponent } from '../storekeeper-navbar/storekeeper-navbar.component';
import { StorekeeperTransferConvertConditioningComponent } from './storekeeper-transfer-convert-conditioning/storekeeper-transfer-convert-conditioning.component';
import { StorekeeperTransferDetailsComponent } from './storekeeper-transfer-details/storekeeper-transfer-details.component';
import { StorekeeperTransferLineDetailsComponent } from './storekeeper-transfer-line-details/storekeeper-transfer-line-details.component';
import { StorekeeperTransferLinesComponent } from './storekeeper-transfer-lines/storekeeper-transfer-lines.component';

@Component({
  selector: 'iu-storekeeper-transfer',
  standalone: true,
  imports: [
    StorekeeperInputComponent,
    StorekeeperButtonComponent,
    StorekeeperEmptyDataComponent,
    StorekeeperNavbarComponent,
    StorekeeperNavbarButtonComponent,
    StorekeeperEmptyDataComponent,
    StorekeeperTransferLineDetailsComponent,
    StorekeeperTransferDetailsComponent,
    StorekeeperTransferLinesComponent,
    StorekeeperTransferLineDetailsComponent,
    NgTemplateOutlet,
    SKWTranslatePipe,
    CdkVirtualScrollableElement,
    StorekeeperTransferConvertConditioningComponent
  ],
  templateUrl: './storekeeper-transfer.component.html',
  styleUrl: './storekeeper-transfer.component.scss',
  providers: [SKWTransferService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorekeeperTransferComponent implements OnInit {
  readonly #SKWNavigationService = inject(SKWNavigationService);
  readonly #SKWContextService = inject(SKWContextService);
  readonly #SKWDataService = inject(SKWDataService);
  readonly #SKWMessageService = inject(SKWMessageService);
  readonly #SKWScannerService = inject(SKWScannerService);

  state = this.#SKWContextService.state;
  tabActive = this.#SKWNavigationService.detailTabActive;
  listStatusTabActive = this.#SKWNavigationService.listStatusTabActive;

  SKWTransferService = inject(SKWTransferService);
  data = this.SKWTransferService.data;
  selectedLine = this.SKWTransferService.selectedLine;
  lines = this.SKWTransferService.lines;
  linesDisplayed = this.SKWTransferService.linesDisplayed;
  isLinesComplete = this.SKWTransferService.isLinesComplete;
  isPalette = this.SKWTransferService.isPalette;
  isConditioning = this.SKWTransferService.isConditioning;
  isComplete = this.SKWTransferService.isComplete;
  canAddLine = this.SKWTransferService.canAddLine;
  hasLines = this.SKWTransferService.hasLines;

  isNewTransfer = this.SKWTransferService.isNewTransfer;
  isConvertingToCond = this.SKWTransferService.isConvertingToConditioning;

  container = viewChild(CdkVirtualScrollableElement);

  linesCompRef = viewChild(StorekeeperTransferLinesComponent);

  constructor() {
    effect(() => {
      this.linesCompRef()?.listen(this.container()?.elementScrolled());
    });
  }

  ngOnInit(): void {
    if (this.isNewTransfer()) {
      this.SKWTransferService.newLine(true);
    }

    // TODO: refactor this
    this.#SKWScannerService.scanner
      .pipe(
        tap((value) => {
          if (
            value.toLocaleLowerCase().startsWith('v') &&
            !this.isComplete() &&
            !this.selectedLine() &&
            !this.isConvertingToCond() &&
            !this.state.pending() &&
            this.isLinesComplete()
          ) {
            this.save();
          }
        })
      )
      .subscribe();
  }

  changeActiveTab(event: MouseEvent, tabType: SKWLineStatus) {
    this.#SKWNavigationService.changeDetailTabActive(tabType);
  }

  save(event?: MouseEvent) {
    if (!this.isLinesComplete()) {
      return;
    }

    this.#SKWContextService.newAction({
      type: SKWStateActionType.SAVE,
      isLoading: true,
      source: this.#SKWDataService.finishTask(this.data()).pipe(
        tap((result) => {
          if (result.error) {
            this.#SKWMessageService.addMessage({
              type: SKWMessageType.ERROR,
              title: 'ValidationTransferError',
              content: result.error,
              key: 'finishTaskError'
            });
            return;
          }

          this.#SKWContextService.refresh();
          this.#SKWNavigationService.previousPage();
        }),
        map(() => undefined)
      )
    });
  }
}
