import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import {
  SearchData,
  SKWLineStatus,
  SKWListTabType,
  SKWPage,
  SKWTransferLineData
} from '../../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../../pipes/storekeeper-window-translate.pipe';
import { SKWContextService } from '../../../services/storekeeper-window-context.service';
import { SKWNavigationService } from '../../../services/storekeeper-window-navigation.service';
import { SKWTransferService } from '../../../services/storekeeper-window-transfer.service';
import { SKWVirtualScrollDirective } from '../../../utils/storekeeper-window-virtual-scroll.directive';
import { StorekeeperButtonComponent } from '../../storekeeper-button/storekeeper-button.component';
import { StorekeeperEmptyDataComponent } from '../../storekeeper-empty-data/storekeeper-empty-data.component';
import { StorekeeperLoadingComponent } from '../../storekeeper-loading/storekeeper-loading.component';
import { StorekeeperWindowInputScanComponent } from '../../storekeeper-window-input-scan/storekeeper-window-input-scan.component';

@Component({
  selector: 'iu-storekeeper-transfer-lines',
  standalone: true,
  imports: [
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    NgTemplateOutlet,
    StorekeeperEmptyDataComponent,
    StorekeeperLoadingComponent,
    StorekeeperButtonComponent,
    StorekeeperWindowInputScanComponent,
    SKWTranslatePipe
  ],
  templateUrl: './storekeeper-transfer-lines.component.html',
  styleUrl: './storekeeper-transfer-lines.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorekeeperTransferLinesComponent extends SKWVirtualScrollDirective {
  readonly #SKWContextService = inject(SKWContextService);
  readonly #SKWNavigationService = inject(SKWNavigationService);
  SKWTransferService = inject(SKWTransferService);

  data = this.SKWTransferService.data;
  isPalette = this.SKWTransferService.isPalette;
  isConditioning = this.SKWTransferService.isConditioning;
  isComplete = this.SKWTransferService.isComplete;
  isLinesComplete = this.SKWTransferService.isLinesComplete;
  lines = this.SKWTransferService.linesDisplayed;
  hasLines = this.SKWTransferService.hasLines;

  isNewTransfer = this.SKWTransferService.isNewTransfer;

  state = this.#SKWContextService.state;

  canAddLine = this.SKWTransferService.canAddLine;
  tabActive = this.#SKWNavigationService.detailTabActive;

  openNewLine = output<MouseEvent>();

  constructor() {
    super();
    this.SKWTransferService.fetchInitData();
  }

  override handleScrollProgress(): void {
    if (this.isConditioning() && this.tabActive() === SKWLineStatus.UL) {
      return;
    }

    super.handleScrollProgress();
  }

  override fetchMoreData(force?: boolean): void {
    this.SKWTransferService.fetchMoreData(force);
  }

  handleSearch({ line, data }: SearchData) {
    this.SKWTransferService.selectLine(line, data);
  }

  selectLine(event: MouseEvent, line: SKWTransferLineData) {
    this.SKWTransferService.selectLine(line);
  }

  addNewLine(event?: MouseEvent) {
    this.SKWTransferService.newLine();
  }

  trackByFn(index: number, item: SKWTransferLineData) {
    return index + item.Line_ID;
  }

  backToTasks(event: MouseEvent) {
    this.#SKWNavigationService.changeListTabActive(SKWListTabType.TASKS);
    this.#SKWNavigationService.changePage(SKWPage.LIST);
  }
}
