//#Start CUSTO-SAMVAZ
import { NgClass } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, inject } from '@angular/core';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { CacheManagerService, TableName } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import InputSwitchUiComponent from '@iupics-components/standard/fields/input-switch-ui/input-switch-ui.component';

@Component({
  selector: 'iu-inout-confirm-batch',
  templateUrl: './inout-confirm-batch.component.html',
  styleUrls: ['./inout-confirm-batch.component.scss'],
  standalone: true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    ButtonUiComponent,
    DynamicContainerDirective,
    NgClass,
    TranslateModule,
    SidebarModule,
    ModalUiComponent,
    InputSwitchUiComponent
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
  ],
})
export default class InOutConfirmBatchComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  numberFormat = '';
  @Input()
  _precision = -1;
  set precision(value: any) {
    this._precision = value;
    if (value >= 0) {
      const stdPrecicion = this.connectorService.getIupicsUserContext()['#StdPrecision'];
      if (stdPrecicion != null && stdPrecicion != undefined && value > stdPrecicion) {
        this.numberFormat = `1.${stdPrecicion}-${value}`;
      } else {
        this.numberFormat = `1.${value}-${value}`;
      }
    }
  }

  get precision() {
    return this._precision;
  }

  private cacheService = inject(CacheManagerService);

  ngOnInit() {
    this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
      specificWindow.items.forEach((item) => {
        if (item !== undefined) {
          if (item.component === 'GridViewUiComponent') {
            item.data.hasCheckbox = true;
            const colConfirmedQty = item.data.columnsTableHeader.find(function (element) {
              return element.field === 'ConfirmedQty';
            });
            if (colConfirmedQty) {
              colConfirmedQty.editable = true;
            }
          }
        }
      });
      super.ngOnInit();
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  
  onCellEdited(event: any) {
    if (!(event.column.getColId() === 'XX_IsDiffBalance')) {
      if (event.newValue != event.oldValue) {
        event.node.setSelected(true);
        event.data['ValueChanged'] = 'Y';
      }
      // change event make put value as string so we parse it to int
      if (event.value === null) {
        event.value = 0;
      }

      const cacheName = TableName.UOM;
      const id = event.data['C_UOM_ID'];
      if (cacheName !== null && id > 0) {
        this.subscriptions.push(
          this.cacheService.getPrecision({tableName :cacheName , id:id}).subscribe((precision) => {
            this.precision = precision;
            event.value = parseFloat(parseFloat(event.newValue).toFixed(this.precision));
            event.data[event.colDef.field] = parseFloat(parseFloat(event.newValue).toFixed(this.precision));
            const targetQty = event.data['TargetQty'];
            const scrappedQty = event.data['ScrappedQty'];
            event.data['DifferenceQty'] = targetQty - scrappedQty - event.value;
            event.node.setData(event.data);
          })
        );
      } else {
            event.value = parseFloat(event.newValue);
            event.data[event.colDef.field] = parseFloat(event.newValue);
            const targetQty = event.data['TargetQty'];
            const scrappedQty = event.data['ScrappedQty'];
            event.data['DifferenceQty'] = targetQty - scrappedQty - event.value;
            event.node.setData(event.data);
      }
  }
  }

  onBtnClick(event: any) {
    if (!(event.column.getColId() === 'XX_IsDiffBalance')) {
        if (event.newValue != event.oldValue) {
          event.row.setSelected(true);
          event.data['ValueChanged'] = 'Y';
        }
        // change event make put value as string so we parse it to int
        if (event.value === null) {
          event.value = 0;
        }

        const id = event.data['C_UOM_ID'];
        if (id > 0) {
          this.subscriptions.push(
            this.cacheService.getPrecision({ tableName: TableName.UOM, id }).subscribe((precision) => {
              this.precision = precision;
              event.value = parseFloat(parseFloat(event.newValue).toFixed(this.precision));
              event.data[event.colDef.field] = parseFloat(parseFloat(event.newValue).toFixed(this.precision));
              const targetQty = event.data['TargetQty'];
              const scrappedQty = event.data['ScrappedQty'];
              event.data['DifferenceQty'] = targetQty - scrappedQty - event.value;
              event.row.setData(event.data);
            })
          );
        } else {
          event.value = parseFloat(event.newValue);
          event.data[event.colDef.field] = parseFloat(event.newValue);
          const targetQty = event.data['TargetQty'];
          const scrappedQty = event.data['ScrappedQty'];
          event.data['DifferenceQty'] = targetQty - scrappedQty - event.value;
          event.row.setData(event.data);
        }
      }
  }

  checkBeforeProcessing() {
    const grid = this.dataStore.data['selections'][0];
    grid['selection'] = [];
    const concernedDocIds = [];
    this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getSelectedNodes().forEach((node) => {
      const data = node.getRowData();
      if (!concernedDocIds.find((id) => id === data.M_InOutConfirm_ID)) {
        concernedDocIds.push(data.M_InOutConfirm_ID);
      }
    });
    this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.forEachNode((node) => {
      const data = node.getRowData();
      if (
        node.isSelected() ||
        (concernedDocIds.find((id) => id === data.M_InOutConfirm_ID) && data.ValueChanged === 'Y')
      ) {
        grid['selection'].push(data);
      }
    });
    return grid['selection'].length > 0;
  }
}
