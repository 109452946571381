<!-- #START CUSTO-SAMVAZ -->
<div
  [ngClass]="[!isModal ? 'iu-specific-window-container' : '', 'iu-specific-window fast order']"
  [attr.data-cy-specific-window-name]="name"
>
  <p-progressBar
    *ngIf="!isModal && isLoading"
    [mode]="'indeterminate'"
    [value]="0"
    [showValue]="false"
    [styleClass]="'loading-progressbar'"
  ></p-progressBar>
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div class="menu-container">
      <h1>{{ 'specificWindow.quickOrder.title' | translate }} ( {{ docStatus }} )</h1>
      <button
        pButton
        type="button"
        icon="icon-attach3x"
        *ngIf="dataStore && dataStore.data && dataStore.data['C_Order_ID']"
        (mouseup)="updateModalDisplay({ key: 'displayJoinFilesPanel', value: true })"
        data-cy="button-attachFile"
      >
        <span
          *ngIf="nbUploadedFiles !== undefined && nbUploadedFiles > 0"
          class="iupics-badge iupics-badge-count"
          style="top: 0; padding-left: 0; right: -1px"
          >{{ nbUploadedFiles > 9 ? '9+' : nbUploadedFiles }}</span
        >
      </button>
      <button
        pButton
        type="button"
        icon="icon-print"
        *ngIf="dataStore && dataStore.data && dataStore.data['C_Order_ID']"
        (mouseup)="updateModalDisplay({ key: 'displayPrintDataModal', value: true })"
        data-cy="button-print"
      ></button>
      <button
        pButton
        type="button"
        icon="fas fa-cog"
        (mouseup)="isSidebarOpen = !isSidebarOpen"
        data-cy="button-setting"
      ></button>
    </div>
    <div id="page-wrap">
      <div id="customer">
        <div class="row mainInfo">
          <table class="metaleft p-lg-3 p-md-6 p-sm-6 p-col-12" id="meta">
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.docType' | translate }}</td>
              <td class="p-grid"><ng-template #vcrDocType></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.customer' | translate }}</td>
              <td class="p-grid"><ng-template #vcrBPartner></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.billLocation' | translate }}</td>
              <td class="p-grid"><ng-template #vcrBillLocation></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.customerLocation' | translate }}</td>
              <td class="p-grid"><ng-template #vcrBPartnerLocation></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.constSiteAddress' | translate }}</td>
              <td class="p-grid"><ng-template #vcrConstSiteAddress></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.technician' | translate }}</td>
              <td class="p-grid"><ng-template #vcrTechnician></ng-template></td>
            </tr>
            <tr class="unselectable" (click)="$event.stopPropagation(); $event.preventDefault()">
              <td (click)="$event.stopPropagation(); $event.preventDefault()" class="meta-head">
                {{ 'specificWindow.quickOrder.fitter' | translate }}
              </td>
              <td (click)="$event.stopPropagation(); $event.preventDefault()" class="p-grid">
                <ng-template #vcrFitter></ng-template>
              </td>
            </tr>
          </table>
          <table class="metaleft p-lg-3 p-md-6 p-sm-6 p-col-12" id="meta">
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.MWarehouse' | translate }}</td>
              <td class="p-grid"><ng-template #vcrMWarehouse></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.description' | translate }}</td>
              <td class="p-grid"><ng-template #vcrDescription></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.billUser' | translate }}</td>
              <td class="p-grid"><ng-template #vcrBillUser></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.user' | translate }}</td>
              <td class="p-grid"><ng-template #vcrAdUser></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.constSiteUser' | translate }}</td>
              <td class="p-grid"><ng-template #vcrConstSiteUser></ng-template></td>
            </tr>
            <!-- <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.deliveryViaRule' | translate }}</td>
              <td class="p-grid"><ng-template #vcrDeliveryViaRule></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.shipper' | translate }}</td>
              <td class="p-grid"><ng-template #vcrMShipper></ng-template></td>
            </tr> -->
          </table>
          <div class="metaright apiz-p-grid p-lg-3 p-md-6 p-sm-6 p-col-12">
            <ng-template #vcrMainButtons></ng-template>
            <ng-template #vcrLastMainButton></ng-template>
            <div class="p-col-12">
              <label>{{ 'specificWindow.quickOrder.salesReps' | translate }}</label>
              <div class="p-col-12 p-chips quickOrder-chips p-component">
                <ul class="p-inputtext p-chips-multiple-container p-disabled">
                  <li class="p-chips-token" *ngFor="let chip of chipsValue">
                    <span class="p-chips-token-label">{{ chip.name }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <ng-template #vcrDeliveryViaRule></ng-template>
            <ng-template #vcrMShipper></ng-template>
          </div>
          <table class="metaright p-lg-3 p-md-6 p-sm-6 p-col-12" id="meta">
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.order' | translate }}</td>
              <td class="p-grid"><ng-template #vcrOrder></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.poReference' | translate }}</td>
              <td class="p-grid"><ng-template #vcrPOReference></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.dateOrdered' | translate }}</td>
              <td class="p-grid"><ng-template #vcrDateOrdered></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.datePromised' | translate }}</td>
              <td class="p-grid"><ng-template #vcrDatePromised></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.comment' | translate }}</td>
              <td class="p-grid"><ng-template #vcrComment></ng-template></td>
            </tr>
            <tr>
              <td class="meta-head">{{ 'specificWindow.quickOrder.weight' | translate }}</td>
              <td class="p-grid"><ng-template #vcrWeight></ng-template></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="total-header p-col-12 p-md-12 p-lg-12" id="meta">
        <div class="meta-block p-col-12 p-sm-6 p-md-6 p-lg-4">
          <div class="meta-head p-col-12 p-sm-4 p-md-4 p-lg-4">
            {{ 'specificWindow.quickOrder.grandNetTotal' | translate }}
          </div>
          <div class="meta-body p-col-12 p-sm-8 p-md-8 p-lg-8">
            <iu-input-number-ui
              [isStandalone]="true"
              [isReadOnly]="true"
              [fieldValue]="this.grandNetTotal"
              label=""
              [data]="grandTotalData"
              [currency]="currency"
              [cssClass]="'p-col-12'"
            ></iu-input-number-ui>
          </div>
        </div>
        <div class="meta-block p-col-12 p-sm-6 p-md-6 p-lg-4">
          <div class="meta-head p-col-12 p-sm-4 p-md-4 p-lg-4">
            {{ 'specificWindow.quickOrder.grandVatTotal' | translate }}
          </div>
          <div class="meta-body p-col-12 p-sm-8 p-md-8 p-lg-8">
            <iu-input-number-ui
              [isStandalone]="true"
              [isReadOnly]="true"
              [fieldValue]="this.grandVatTotal"
              label=""
              [data]="grandTotalData"
              [currency]="currency"
              [cssClass]="'p-col-12'"
            ></iu-input-number-ui>
          </div>
        </div>
        <div class="meta-block p-col-12 p-sm-6 p-md-6 p-lg-4">
          <div class="meta-head p-col-12 p-sm-4 p-md-4 p-lg-4">{{ 'specificWindow.quickOrder.grandTotal' | translate }}</div>
          <div class="meta-body p-col-12 p-sm-8 p-md-8 p-lg-8">
            <iu-input-number-ui
              [isStandalone]="true"
              [isReadOnly]="true"
              [fieldValue]="this.grandTotal"
              label=""
              [data]="grandTotalData"
              [currency]="currency"
              [cssClass]="'p-col-12'"
            ></iu-input-number-ui>
          </div>
        </div>
        <div class="meta-block p-col-12 p-sm-6 p-md-6 p-lg-12">
          <div class="meta-body button p-col-12 p-md-12 p-lg-12">
            <ng-template #vcrLineButtons></ng-template>
            <button
              *ngIf="!isProcessed"
              [ngClass]="[
                dataStore &&
                dataStore.data &&
                dataStore.data['DocStatus'] &&
                (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')
                  ? 'disabledButton'
                  : '',
                'button p-button-error cancelButton'
              ]"
              (click)="$event.preventDefault(); $event.stopPropagation(); cancelDoc()"
              pButton
              label=" {{ 'specificWindow.quickOrder.cancel' | translate }}"
              icon="fas fa-ban"
            ></button>
            <button
              [ngClass]="[
                dataStore && dataStore.data && (!dataStore.data['C_Order_ID'] || isProcessed) ? 'disabledButton' : '',
                'button p-button-success saveButton'
              ]"
              (click)="$event.preventDefault(); $event.stopPropagation(); processOrder()"
              pButton
              label="{{ 'specificWindow.processing' | translate }}"
              icon="{{
                dataStore &&
                dataStore.data['DocStatus'] &&
                (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')
                  ? 'fa fa-ban'
                  : 'fa fa-check'
              }}"
              [disabled]="isProcessed"
            ></button>
            <button
              *ngIf="isProcessed"
              pButton
              class="button p-button-warning"
              (click)="$event.preventDefault(); $event.stopPropagation(); resetOrder()"
              label="{{ 'specificWindow.quickOrder.newOrder' | translate }}"
              icon="fa fa-plus"
            ></button>
          </div>
        </div>
      </div>
      <div #linesContainer class="items-container">
        <table id="items">
          <colgroup>
            <col class="lineCol" />
            <col class="isDescriptionCol" />
            <col class="chargeCol" />
            <col class="productCol" />
            <col class="XX_RelatedProductCol" />
            <col class="quantityCol" />
            <col class="uomCol" />
            <col class="trialPhaseDaysCol" />
            <col class="descriptionCol" />
            <col class="priceActualCol" />
            <col class="XX_IsNetPriceCol" />
            <col class="priceListCol" />
            <col class="qtyUniCol" />
            <col class="xUomCol" />
            <col class="discountCol" />
            <col class="totalLineCol" />
            <!-- <col class="taxCol" /> -->
            <col class="groupLineCol" />
            <col class="chargeCol" />
            <col class="deleteCol" />
          </colgroup>
          <thead>
            <tr>
              <th style="min-width: 65px">{{ translations.line }}</th>
              <th style="min-width: 65px">{{ translations.isDescription }}</th>
              <th style="min-width: 200px">{{ translations.product }}</th>
              <th style="min-width: 150px">{{ translations.XX_RelatedProduct }}</th>
              <th style="min-width: 150px">{{ translations.quantity }}</th>
              <th style="min-width: 70px">{{ translations.uom }}</th>
              <th style="min-width: 65px">{{ translations.trialphasedays }}</th>
              <th style="min-width: 400px">{{ translations.description }}</th>
              <th style="min-width: 102px">{{ translations.priceActual }}</th>
              <th style="min-width: 65px">{{ translations.XX_IsNetPrice }}</th>
              <th style="min-width: 102px">{{ translations.priceList }}</th>
              <th style="min-width: 150px">{{ translations.qtyunit }}</th>
              <th style="min-width: 70px">{{ translations.xUom }}</th>
              <th style="min-width: 67px">{{ translations.discount }}</th>
              <th style="min-width: 150px" class="alignRight">{{ translations.totalLine }}</th>
              <!-- <th class="alignRight">{{ translations.tax }}</th> -->
              <th style="min-width: 150px">{{ translations.groupLine }}</th>
              <th style="min-width: 200px">{{ translations.charge }}</th>
              <th style="min-width: 50px" class="alignRight"></th>
            </tr>
          </thead>
          <tbody>
            @for (item of nbLines; track item; let i = $index) {
              <ng-container>
              <tr class="item-row" [ngClass]="{ notPersisted: !dataLines[i] || !dataLines[i].data['C_OrderLine_ID'] }">
                <td [attr.data-label]="translations.line" class="fastorder-line">
                  <ng-template #vcrsLines></ng-template>
                </td>
                <td [attr.data-label]="translations.isDescription" class="fastorder-isDescription">
                  <ng-template #vcrsIsDescription></ng-template>
                </td>
                <td [attr.data-label]="translations.product" class="fastorder-product">
                  <ng-template #vcrsProduct></ng-template>
                </td>
                <td [attr.data-label]="translations.XX_RelatedProduct" class="fastorder-qty">
                  <ng-template #vcrsXX_RelatedProduct ></ng-template>
                </td>
                <td [attr.data-label]="translations.quantity" class="fastorder-qty"><ng-template #vcrsQty></ng-template></td>
                <td [attr.data-label]="translations.uom" class="fastorder-UOM"><ng-template #vcrsUOM></ng-template></td>
                <td [attr.data-label]="translations.trialphasedays" class="fastorder-trialphasedays">
                  <ng-template #vcrsXX_TrialPhaseDays></ng-template>
                </td>
                <td [attr.data-label]="translations.description" class="fastorder-description">
                  <ng-template #vcrsDescription></ng-template>
                </td>
                <td [attr.data-label]="translations.priceActual" class="fastorder-priceList">
                  <ng-template #vcrsPriceActual></ng-template>
                </td>
                <td [attr.data-label]="translations.XX_IsNetPrice" class="fastorder-isDescription">
                  <ng-template #vcrsXX_IsNetPrice></ng-template>
                </td>
                <td [attr.data-label]="translations.priceList" class="fastorder-price">
                  <ng-template #vcrsPriceList></ng-template>
                </td>
                <td [attr.data-label]="translations.qtyunit" class="fastorder-qtyunit">
                  <ng-template #vcrsXX_QtyUnit></ng-template>
                </td>
                <td [attr.data-label]="translations.xUom" class="fastorder-xuom">
                  <ng-template #vcrsXX_UOM_ID></ng-template>
                </td>
                <td [attr.data-label]="translations.discount" class="fastorder-discount">
                  <ng-template #vcrsDiscount></ng-template>
                </td>
                <!-- <td class="fastorder-priceentered"><ng-template #vcrsPriceEntered></ng-template></td> -->
                <td [attr.data-label]="translations.totalLine" class="fastorder-linetotal">
                  <ng-template #vcrsLineTotalAmt></ng-template>
                </td>
                <!-- <td [attr.data-label]="translations.tax" class="fastorder-tax"><ng-template #vcrsC_Tax_ID></ng-template></td> -->
                <td [attr.data-label]="translations.groupLine" class="fastorder-groupline">
                  <ng-template #vcrsXX_GroupLine></ng-template>
                  <div [hidden]="true"><ng-template #vcrsXX_IsComplexPrice></ng-template></div>
                </td>
                <td [attr.data-label]="translations.charge" class="fastorder-charge">
                  <ng-template #vcrsCharge></ng-template>
                </td>
                <td class="deleteButton">
                  <button
                    *ngIf="i < $any(dataLines).length"
                    [ngClass]="[
                      dataStore &&
                      dataStore.data['DocStatus'] &&
                      (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')
                        ? 'disabledButton'
                        : ''
                    ]"
                    tooltipPosition="top"
                    showDelay="250"
                    life="3"
                    pTooltip="{{ 'generic.delete' | translate }}"
                    pButton
                    icon="icon-delete"
                    (click)="removeLine(dataLines[i], i)"
                    [disabled]="isProcessed"
                  ></button>
                </td>
                <td [hidden]="true" class="deleteButton">
                  <ng-template #vcrsPriceEntered> </ng-template>
                </td>
              </tr>
            </ng-container>
            }
            
          </tbody>
        </table>
      </div>
      <div id="hiderow">
        <button
          id="addrow"
          [ngClass]="[
            dataStore &&
            (!dataStore.data['C_Order_ID'] ||
              (dataStore.data['DocStatus'] && (dataStore.data['DocStatus'] === 'CO' || dataStore.data['DocStatus'].id === 'CO')))
              ? 'disabledButton'
              : '',
            'p-button p-button-secondary'
          ]"
          title="Add a row"
          (click)="newLine()"
        >
          <span class="icon-add"></span>{{ 'specificWindow.quickOrder.addrow' | translate }}
        </button>
      </div>
    </div>
    <div style="height: 70px"></div>
  </p-scrollPanel>
  <p-sidebar [(visible)]="isSidebarOpen" [baseZIndex]="6000" position="right" styleClass="p-sidebar-md ">
    <div class="apiz-p-grid">
      <h1 class="iu-notification-center-title">
        {{ 'specificWindow.quickOrder.settings' | translate }}
      </h1>
      <ng-template #vcrSettings></ng-template>
    </div>
  </p-sidebar>
</div>
<!-- <ng-container *ngIf="displaySearch">
  <iu-modal-ui
    data-cy="search-panel"
    [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
    [title]="this.searchLinkedComponent.autoComplete.label"
    [hasCloseBtn]="true"
    (closeModalEmitter)="displaySearch = false"
  >
    <div class="autocomplete-universal-filter">
      <iu-universal-filter-ui
        [tabId]="-1"
        [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
        [gridTab]="gridTab"
      ></iu-universal-filter-ui>
    </div>
    <iu-grid-tab-infinity-scroll-ui
      #gridTab
      (searchEmitter)="this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false"
      [data]="this.searchLinkedComponent.autoComplete.data"
      [container]="this"
      [isSearch]="true"
      [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
    ></iu-grid-tab-infinity-scroll-ui>
  </iu-modal-ui>
</ng-container> -->
<div [ngClass]="[!displayFormUI ? 'hidden' : '', 'specificModal']">
  <iu-modal-ui
    [title]="specificWindowTitle"
    [isModalDisplay]="displayFormUI"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false })"
  >
    <ng-template #vcrSpecific></ng-template>
  </iu-modal-ui>
</div>
<div *ngIf="displayProcessUI">
  <iu-modal-ui
    (closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
    [hasCloseBtn]="true"
    [angularStyle]="{ 'iu-modal-body': { padding: 0 } }"
    [contentType]="'process'"
  >
    <iu-process-ui
      (closeModalEmitter)="updateModalDisplay({ key: 'displayProcessUI', value: false })"
      [windowId]="processId"
      [parentComponent]="this"
      [isModal]="true"
    ></iu-process-ui>
  </iu-modal-ui>
</div>
<ng-container *ngIf="displayJoinFilesPanel">
  <iu-modal-ui
    [title]="'joinFiles.title'"
    [hasCloseBtn]="true"
    [contentType]="'joinFiles'"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayJoinFilesPanel', value: false })"
  >
    <iu-join-file-ui
      [dsKey]="dsKey"
      [adTable_ID]="tableID"
      [adTab_ID]="orderTabID"
      [(nbUploadedFiles)]="nbUploadedFiles"
      [data]="data"
    ></iu-join-file-ui>
  </iu-modal-ui>
</ng-container>
<ng-container *ngIf="displayPrintDataModal">
  <iu-modal-ui
    #printDataModal
    [title]="'print.title'"
    (closeModalEmitter)="updateModalDisplay({ key: 'displayPrintDataModal', value: false })"
    [angularStyle]="{ 'iu-modal-body': { overflow: 'unset' } }"
  >
    <print-ui
      [dsKey]="dsKey"
      [uuid]="'fastCreateOrder'"
      (cancelPrintDataEmitter)="updateModalDisplay({ key: 'displayPrintDataModal', value: false })"
      [sourceModal]="printDataModal"
      [printCtx]="this.dataStore.currentContext"
    ></print-ui>
  </iu-modal-ui>
</ng-container>
