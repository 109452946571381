//#START CUSTO-SAMVAZ
import {AfterViewInit, Component, ComponentFactoryResolver, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import EditViewUiComponent  from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ProgressBarModule } from 'primeng/progressbar';
import { NgClass } from '@angular/common';
@Component({
  selector: 'iu-create-from-open-invoice-st-window-ui',
  templateUrl: './create-from-open-invoice-st-window-ui.component.html',
  styleUrls: ['./create-from-open-invoice-st-window-ui.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    ButtonUiComponent,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    TranslateModule,
  ],
})
export class CreateFromOpenInvoiceStWindowUiComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  @ViewChild('left', { read: ViewContainerRef, static: true })
  vcrLeft: ViewContainerRef;
  @ViewChild('middle', { read: ViewContainerRef, static: true })
  vcrMiddle: ViewContainerRef;
  @ViewChild('right', { read: ViewContainerRef, static: true })
  vcrRight: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  @Output()
  vcrBot: ViewContainerRef;
  SumSelectedRows = 0;
  countOfRows = 0;
  constructor() {
    super();
    this.isModal = false;
  }
  ngOnInit() {

    this.customDesignArray.push(
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BPartner_ID',
        cssClass: 'p-col-6 p-md-4 p-lg-2'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'IsSOTrx',
        cssClass: 'p-col-6 p-md-4 p-lg-2'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_DocType_ID',
        cssClass: 'p-col-6 p-md-4 p-lg-2'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BankStatementLine_ID',
        cssClass: 'p-col-6 p-md-4 p-lg-2'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'C_BankStatement_ID',
        cssClass: 'p-col-6 p-md-4 p-lg-2'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.GRID,
        tableName: 'Create From Open InvoiceST Table',
        cssClass: 'p-col-12'
      },
      {
        vcr: 'vcr',
        type: CustomDesignItemType.FIELD,
        columnName: 'Total',
        cssClass: 'p-col-6 p-md-4 p-lg-2'
      },
      {
        vcr: 'vcrButtons',
        type: CustomDesignItemType.FIELD,
        columnName: 'Processing',
        btnType: 'primary',
        cssClass: 'p-col-6 p-md-4 p-lg-2'
      }
    );
    if (!this.dataStore) {
      this.dataStore = this.store.newSpecificWindowData(this.formId);
    }
    super.ngOnInit();
   if (!this.dataStore) {
    this.dataStore = this.store.newSpecificWindowData(this.formId);
  }
  if (this.parentComponent instanceof EditViewUiComponent) {
    this.showSpecificWindow();
  }
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
    // Global.workspace = this;
  }

   notifyFromRowSelected(rowSelected: any) {
    let total = 0;
    this.countOfRows = this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getRowCount();
    const lineSelecteds = this.getGridSelection('Create From Open InvoiceST Table');
    if (rowSelected.length === 0) {
      this.dataStore.data.c = this.getField('Total').data.defaultValue;
    }
    if (lineSelecteds && lineSelecteds.length > 0) {
      lineSelecteds.forEach((rowData) => {
        if (rowData['Open'] !== undefined && rowData['Open'] !== null) {
          total += rowData['Open'];
        }

      });
    }
    this.setFormStoreData('Total', total.toFixed(2));
    this.setDataContainersValueWithChangedStore();
  }
}
//#END CUSTO-SAMVAZ