import { NgClass } from '@angular/common';
import { Component, DestroyRef, ElementRef, OnInit, Signal, inject, input, model, output, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Subject, buffer, debounceTime, map, tap } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { SKWInputType } from '../../models/storekeeper-input.model';
import { SKWScannerService } from '../../services/storekeeper-scanner.service';

@Component({
  selector: 'iu-storekeeper-input',
  standalone: true,
  imports: [NgClass],
  templateUrl: './storekeeper-input.component.html',
  styleUrl: './storekeeper-input.component.scss'
})
export class StorekeeperInputComponent implements OnInit {
  readonly #SKWScannerService = inject(SKWScannerService);
  readonly uuid = uuid();

  isScanInput = input(false);

  type = input.required<SKWInputType>();
  label = input.required<string>();
  value = model.required<string | number>();
  readOnly = input<boolean>(false);
  placeholder = input<string>('');

  validation = input<boolean>(undefined);
  onReadings = output<string[]>();

  input: Signal<ElementRef<HTMLInputElement>> = viewChild<ElementRef>('input');

  readonly #keypressSubject = new Subject<KeyboardEvent>();
  keypress = this.#keypressSubject.pipe(
    buffer(this.#keypressSubject.pipe(debounceTime(this.#SKWScannerService.SCAN_COMPLETE_TIMEOUT))),
    map((events) => {
      let inBetweenFlags = false;
      let readings: string[] = [];
      let value = '';
      for (const { key } of events) {
        if (key === this.#SKWScannerService.CHAR_FLAG) {
          inBetweenFlags = !inBetweenFlags;
        } else if (inBetweenFlags) {
          readings[readings.length] = (readings[readings.length] ?? '') + key;
        }
        value += key;
      }

      return { value, readings };
    }),
    tap(({ value, readings }) => {
      if (readings.length > 0) {
        this.onReadings.emit(readings);
      } else {
        this.manualUpdateValue(value);
      }
    })
  );

  constructor() {
    this.keypress.pipe(takeUntilDestroyed(inject(DestroyRef))).subscribe();
  }

  ngOnInit(): void {
    if (this.type() === SKWInputType.TEXT && this.value() === undefined) {
      this.value.set('');
    }
  }

  updateValue(event: InputEvent) {
    const input = <HTMLInputElement>event.target;
    this.value.set(this.type() === SKWInputType.NUMBER ? input.valueAsNumber : input.value);
    if (this.isScanInput()) {
      this.onReadings.emit(['' + this.value()]);
    }
  }

  manualUpdateValue(value: string) {
    const v = (this.input()?.nativeElement?.value ?? '') + value;
    this.value.set(this.type() === SKWInputType.NUMBER ? parseInt(v, 10) : v);
  }

  onKeypress(event: KeyboardEvent) {
    if (this.isIgnoredKey(event.key) || this.isScanInput()) {
      return;
    }

    event.preventDefault();
    this.#keypressSubject.next(event);
  }

  isIgnoredKey(key: string) {
    const ignoredKeys = [
      'Shift',
      'Control',
      'Alt',
      'Tab',
      'Enter',
      'Escape',
      'CapsLock',
      'ArrowUp',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'Backspace',
      'Delete',
      'Meta',
      'F1',
      'F2',
      'F3',
      'F4',
      'F5',
      'F6',
      'F7',
      'F8',
      'F9',
      'F10',
      'F11',
      'F12'
    ];

    return ignoredKeys.includes(key);
  }
}
