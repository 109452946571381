//#START CUSTO-SAMVAZ
import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
  inject
} from '@angular/core';
import { CompiereDataGridFilterType, DataStore, DataStoreKey, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CompiereDataService } from '@compiere-ws/services/compiere-data/compiere-data.service';
import { CompiereWorkflowService } from '@compiere-ws/services/compiere-workflow/compiere-workflow.service';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { PoService } from '@compiere-ws/services/po/po.service';
import { CustomDesignItem, CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import  AutocompleteUiComponent  from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import  ButtonUiComponent  from '@iupics-components/standard/fields/button-ui/button-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { NumberType } from '@iupics-manager/models/iupics-data';
import { IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import ProcessUiComponent, { ProcessParams } from '../process-ui/process-ui.component';
import  SpecificWindowUiComponent  from '../specific-window-ui/specific-window-ui.component';
import {
  getCalloutFieldIds,
  getFormID,
  getHeaderCustomDesignItems,
  getLineCustomDesignItems,
  getLogMessageRequest,
  getNullValueInData,
  getOrderLineRequest,
  getOrderPORefRequestDuplicateBP,
  getOrderPORefRequestDuplicateDT,
  getOrderRequest,
  getPartnerRequest,
  getSalesRepRequest,
  getTabDataRequest,
  getTaxOrderGroupRequest,
  SalesRep
} from './fast-create-order-utils';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { TooltipModule } from 'primeng/tooltip';
import { FakeArrayPipe } from '@iupics-util/pipes/fake-array/fake-array.pipe';
import { TranslateModule } from '@ngx-translate/core';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import InputNumberUiComponent from '@iupics-components/standard/fields/input-number-ui/input-number-ui.component';
import JoinFileUiComponent from '../join-file-ui/join-file-ui.component';
import { PrintUiComponent } from '../print-ui/print-ui.component';
import InputChipsUiComponent from '@iupics-components/standard/fields/input-chips-ui/input-chips-ui.component';
@Component({
  selector: 'iu-fast-create-order',
  templateUrl: './fast-create-order.component.html',
  styleUrls: ['./fast-create-order.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgFor,
    ProgressBarModule,
    ScrollPanelModule,
    ButtonModule,
    InputNumberUiComponent,
    TooltipModule,
    SidebarModule,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    TranslateModule,
    FakeArrayPipe,
    JoinFileUiComponent,
    PrintUiComponent,
    InputChipsUiComponent,
    ProcessUiComponent
  ],
})
export class FastCreateOrderComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  MANDATORY_HEADER_COLUMNS = [
    'C_BPartner_ID',
    'C_BPartner_Location_ID',
    'DateOrdered',
    'AD_Org_ID',
    'DeliveryViaRule',
    'DeliveryRule',
    'DatePromised',
    'C_PaymentTerm_ID',
    'Bill_Location_ID',
    'M_Warehouse_ID',
    'C_DocTypeTarget_ID',
    'M_PriceList_ID',
    'PaymentRule'
  ];
  HEADER_COLUMNS = [
    'C_BPartner_ID',
    'C_BPartner_Location_ID',
    'DateOrdered',
    'AD_Org_ID',
    'SalesRep_ID',
    'DeliveryViaRule',
    'DeliveryRule',
    'Description',
    'M_DiscountSchema_ID',
    'XX_ConstSiteUser_ID',
    'XX_ConstSiteAddress_ID',
    'M_Shipper_ID',
    'XX_Fitter_ID',
    'XX_Technician_ID',
    'POReference',
    'DatePromised',
    'XX_Comment',
    'Weight',
    'C_PaymentTerm_ID',
    'Bill_Location_ID',
    'M_Warehouse_ID',
    'C_DocTypeTarget_ID',
    'POReference',
    'M_PriceList_ID',
    'PaymentRule',
    'IsSOTrx',
    'isQuote',
    'InvoiceRule',
    'Bill_User_ID',
    'AD_User_ID'
  ];
  CALLOUTS_HEADER_COLUMNNAMES = [
    'C_BPartner_Location_ID',
    'XX_ConstSiteAddress_ID',
    'Bill_BPartner_ID',
    'C_BPartner_ID',
    'Bill_Location_ID',
    'M_Warehouse_ID',
    'DateOrdered',
    'C_DocTypeTarget_ID',
    'M_PriceList_ID',
    'AD_Org_ID'
  ];
  CALLOUTS_LINE_COLUMNNAMES = [
    'M_Product_ID',
    'C_Charge_ID',
    'QtyEntered',
    'Discount',
    'PriceActual',
    'PriceList',
    'PriceEntered',
    'C_UOM_ID',
    'XX_QtyUnit',
    'IsDescription'
  ];
  MANDATORY_LINE_COLUMNNAMES = [
    'QtyEntered'
  ];

  @ViewChild('linesContainer', { read: ElementRef, static: true })
  linesContainer: ElementRef;
  @ViewChild('vcrDocType', { read: ViewContainerRef, static: true })
  vcrDocType: ViewContainerRef;
  @ViewChild('vcrBPartner', { read: ViewContainerRef, static: true })
  vcrBPartner: ViewContainerRef;
  @ViewChild('vcrProcessOrder', { read: ViewContainerRef, static: true })
  vcrProcessOrder: ViewContainerRef;
  @ViewChild('vcrDeliveryViaRule', { read: ViewContainerRef, static: true })
  vcrDeliveryViaRule: ViewContainerRef;
  @ViewChild('vcrMShipper', { read: ViewContainerRef, static: true })
  vcrMShipper: ViewContainerRef;

  @ViewChild('vcrConstSiteAddress', { read: ViewContainerRef, static: true })
  vcrConstSiteAddress: ViewContainerRef;
  @ViewChild('vcrBillLocation', { read: ViewContainerRef, static: true })
  vcrBillLocation: ViewContainerRef;
  @ViewChild('vcrBPartnerLocation', { read: ViewContainerRef, static: true })
  vcrBPartnerLocation: ViewContainerRef;
  @ViewChild('vcrTechnician', { read: ViewContainerRef, static: true })
  vcrTechnician: ViewContainerRef;
  @ViewChild('vcrFitter', { read: ViewContainerRef, static: true })
  vcrFitter: ViewContainerRef;
  @ViewChild('vcrMWarehouse', { read: ViewContainerRef, static: true })
  vcrMWarehouse: ViewContainerRef;
  @ViewChild('vcrDescription', { read: ViewContainerRef, static: true })
  vcrDescription: ViewContainerRef;
  @ViewChild('vcrAdUser', { read: ViewContainerRef, static: true })
  vcrAdUser: ViewContainerRef;
  @ViewChild('vcrConstSiteUser', { read: ViewContainerRef, static: true })
  vcrConstSiteUser: ViewContainerRef;
  @ViewChild('vcrBillUser', { read: ViewContainerRef, static: true })
  vcrBillUser: ViewContainerRef;

  @ViewChild('vcrOrder', { read: ViewContainerRef, static: true })
  vcrOrder: ViewContainerRef;

  @ViewChild('vcrPOReference', { read: ViewContainerRef, static: true })
  vcrPOReference: ViewContainerRef;
  @ViewChild('vcrDateOrdered', { read: ViewContainerRef, static: true })
  vcrDateOrdered: ViewContainerRef;
  @ViewChild('vcrDatePromised', { read: ViewContainerRef, static: true })
  vcrDatePromised: ViewContainerRef;
  @ViewChild('vcrComment', { read: ViewContainerRef, static: true })
  vcrComment: ViewContainerRef;
  @ViewChild('vcrWeight', { read: ViewContainerRef, static: true })
  vcrWeight: ViewContainerRef;

  @ViewChild('vcrMainButtons', { read: ViewContainerRef, static: true })
  vcrMainButtons: ViewContainerRef;
  @ViewChild('vcrLastMainButton', { read: ViewContainerRef, static: true })
  vcrLastMainButton: ViewContainerRef;
  @ViewChild('vcrLineButtons', { read: ViewContainerRef, static: true })
  vcrLineButtons: ViewContainerRef;
  @ViewChild('vcrGrandTotal', { read: ViewContainerRef, static: true })
  vcrGrandTotal: ViewContainerRef;

  @ViewChild('vcrSettings', { read: ViewContainerRef, static: true })
  vcrSettings: ViewContainerRef;
  // vcr orderline
  @ViewChildren('vcrsLines', { read: ViewContainerRef })
  vcrsLines: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsIsDescription', { read: ViewContainerRef })
  vcrsIsDescription: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsCharge', { read: ViewContainerRef })
  vcrsCharge: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsProduct', { read: ViewContainerRef })
  vcrsProduct: QueryList<ViewContainerRef>;

  @ViewChildren('vcrsDescription', { read: ViewContainerRef })
  vcrsDescription: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsUOM', { read: ViewContainerRef })
  vcrsUOM: QueryList<ViewContainerRef>;

  @ViewChildren('vcrsQty', { read: ViewContainerRef })
  vcrsQty: QueryList<ViewContainerRef>;

  @ViewChildren('vcrsPriceActual', { read: ViewContainerRef })
  vcrsPriceActual: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsPriceList', { read: ViewContainerRef })
  vcrsPriceList: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsXX_IsNetPrice', { read: ViewContainerRef })
  vcrsXX_IsNetPrice: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsXX_RelatedProduct', { read: ViewContainerRef })
  vcrsXX_RelatedProduct: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsXX_QtyUnit', { read: ViewContainerRef })
  vcrsXX_QtyUnit: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsXX_UOM_ID', { read: ViewContainerRef })
  vcrsXX_UOM_ID: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsPriceEntered', { read: ViewContainerRef })
  vcrsPriceEntered: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsLineTotalAmt', { read: ViewContainerRef })
  vcrsLineTotalAmt: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsDiscount', { read: ViewContainerRef })
  vcrsDiscount: QueryList<ViewContainerRef>;
  // @ViewChildren('vcrsC_Tax_ID', { read: ViewContainerRef })
  // vcrsC_Tax_ID: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsXX_TrialPhaseDays', { read: ViewContainerRef })
  vcrsXX_TrialPhaseDays: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsXX_GroupLine', { read: ViewContainerRef })
  vcrsXX_GroupLine: QueryList<ViewContainerRef>;
  @ViewChildren('vcrsXX_IsComplexPrice', { read: ViewContainerRef })
  vcrsXX_IsComplexPrice: QueryList<ViewContainerRef>;
  // end vcr line

  isSidebarOpen = false;
  subscription;
  docStatus = '*';
  lineMetaData: CustomDesignItem[];
  messages: ADMessage[];
  dataLines: DataStore[] = [];
  dataContainersLine: AbstractDataContainer[][] = [];
  tabindex = 1;
  grandTotal = 0;
  grandVatTotal = 0;
  grandNetTotal = 0;
  grandTotalData = { numberType: NumberType.AMOUNT };
  currency = '';
  ad_language = 'fr_FR';
  isProcessed = false;
  tableID;
  orderTabID;
  orderLineTabID;
  salesRepTabID;
  orderTaxTabID;
  windowID;
  nbUploadedFiles = 0;
  displayJoinFilesPanel = false;
  displayPrintDataModal = false;
  dsKey: DataStoreKey;
  displayProcessUI = false;
  processId = null;
  nbLines= [1];
  modalCloseOption: { refreshOrder: boolean; refreshSalesRep: boolean } = {
    refreshOrder: false,
    refreshSalesRep: false
  };
  calloutFields: any = {};
  chipsValue: SalesRep[] = [];
  translations: any;
  XX_PrintZone_ID;
  printZoneFormID = 1000346;
  saveBlocked = false;

  private poService = inject(PoService);
  private workflowService = inject(CompiereWorkflowService);
  messageManager = inject(MessageManagerService);
  private dataService= inject(CompiereDataService);
  protected translateService= inject(TranslateService);
  private docserverService= inject(DocServerService);
  constructor(
  ) {
    super();
    this.docStatus = this.translateService.instant('specificWindow.quickOrder.new');
    this.isModal = false;
    this.customDesignArray.push(...getHeaderCustomDesignItems());
  }
  ngOnInit() {
    this.ad_language = this.connectorService.getIupicsDefaultLanguage().iso_code;
    this.lineMetaData = getLineCustomDesignItems();
    // INIT
    this.initAD_Data().subscribe(() => {
      super.ngOnInit();
      const item: DynamicComponent = {
        container: this,
        DOMParentComponent: this,
        component: 'SpecificWindowUiComponent',
        cssClass: 'p-col-12',
        isCssOnComponent: false,
        tabId: this.formId,
        gridPaginator: false
      };
      this.windowFactory.newEventHandler({
        type: IupicsTypeEvent.showSpecificWindow,
        item: item
      });
    });
  }

  public updateModalDisplay(
    display: { key: string; value: boolean; sourceComponent?: any },
    id?: { key: string; value: number }
  ) {
    this.customFormModalBodyCss =
      display.key && display.key === 'displayFormUI'
        ? { 'iu-modal-body': { overflow: 'auto', height: 'calc(100% - 25px)' } }
        : undefined;
    this[display.key] = display.value;
    if (id && display.value === true) {
      this[id.key] = id.value;
      if (id.key !== 'processId') {
        this.createSpecificWindow(id.value, display.sourceComponent);
      }
    } else {
      if (display.value === false) {
        if (display.key === 'displayPrintDataModal') {
          this.updateNbFileAttached();
        }
        if (this.modalCloseOption.refreshOrder) {
          this.refreshOrder();
        } else if (this.modalCloseOption.refreshSalesRep) {
          this.refreshSalesRep();
        }
        this.modalCloseOption = {
          refreshOrder: false,
          refreshSalesRep: false
        };
      }
      this.customFormModalBodyCss = undefined;
    }
  }

  processCopyFrom(item: any) {
    // to override button process behaviour
    this.modalCloseOption = { refreshOrder: true, refreshSalesRep: false };
    this.dataStore.data['Record_ID'] = this.dataStore.data['C_Order_ID'];
  }
  processXX_SelectionMode(item: any) {
    // to override button process behaviour
    this.modalCloseOption = { refreshOrder: true, refreshSalesRep: false };
    this.dataStore.data['Record_ID'] = this.dataStore.data['C_Order_ID'];
  }
  processXX_FeesDiscounts(item: any) {
    // to override button process behaviour
    this.modalCloseOption = { refreshOrder: true, refreshSalesRep: false };
    this.dataStore.data['Record_ID'] = this.dataStore.data['C_Order_ID'];
  }
  processXX_Managelines(item: any) {
    // to override button process behaviour
    this.modalCloseOption = { refreshOrder: true, refreshSalesRep: false };
    this.dataStore.data['Record_ID'] = this.dataStore.data['C_Order_ID'];
  }
  processXX_SalesRepSelection(item: any) {
    // to override button process behaviour
    this.modalCloseOption = { refreshOrder: false, refreshSalesRep: true };
    this.dataStore.data['Record_ID'] = this.dataStore.data['C_Order_ID'];
  }
  processXX_RelatedProduct(item: any) {
    this.modalCloseOption = { refreshOrder: true, refreshSalesRep: false };
  }

  /**
   * Overrided to avoid std behaviour of datacontainer in specific window
   */
  setDataContainersValueWithChangedStore(dataStore?: DataStore) {}
  calloutColumn(columnName: string) {
    if (this.dataStore && this.dataStore.data[columnName] !== null && this.dataStore.data[columnName] !== undefined) {
      const value = this.dataStore.data[columnName].id ? this.dataStore.data[columnName].id : this.dataStore.data[columnName];
      this.store.calloutData(
        this.calloutFields[columnName]
          ? '/Field/Callout/' + this.calloutFields[columnName]
          : '/Field/Callout/C_Order/' + columnName,
        {
          columnName: columnName,
          newValue: value,
          windowCtx: this.getCurrentContext()
        },
        this.dataStore,
        null,
        () => {
          this.saveOrder();
        }
      );
    }
  }

  calloutColumnLine(columnName: string, lineDataStore: DataStore) {
    if (lineDataStore && lineDataStore.data[columnName] !== null && lineDataStore.data[columnName] !== undefined) {
      const value =
        lineDataStore.data[columnName] && lineDataStore.data[columnName].id
          ? lineDataStore.data[columnName].id
          : lineDataStore.data[columnName];
      this.store.calloutData(
        this.calloutFields[columnName]
          ? '/Field/Callout/' + this.calloutFields[columnName]
          : '/Field/Callout/C_OrderLine/' + columnName,
        {
          columnName: columnName,
          newValue: value,
          windowCtx: this.getCurrentContext(lineDataStore, this.dataStore)
        },
        lineDataStore,
        null,
        () => {
          this.saveOrderLine(lineDataStore);
        }
      );
    } else {
      this.saveOrderLine(lineDataStore);
    }
  }
  calloutPartner() {
    if (this.dataStore && this.dataStore.data['C_BPartner_ID'] !== null && this.dataStore.data['C_BPartner_ID'] !== undefined) {
      const value = this.dataStore.data['C_BPartner_ID'].id
        ? this.dataStore.data['C_BPartner_ID'].id
        : this.dataStore.data['C_BPartner_ID'];
      this.store.calloutData(
        this.calloutFields['C_BPartner_ID']
          ? '/Field/Callout/' + this.calloutFields['C_BPartner_ID']
          : '/Field/Callout/C_Order/C_BPartner_ID',
        {
          columnName: 'C_BPartner_ID',
          newValue: value,
          windowCtx: this.getCurrentContext()
        },
        this.dataStore,
        null,
        () => {
          this.calloutColumn('AD_Org_ID');
        }
      );
      this.subscriptions.push(
        this.store.getDataGrid(getPartnerRequest(value), true).subscribe(({ data }) => {
          this.store.syncDataChanges(this.dataStore, { XX_Comment: data[0]['XX_GENERALCOMMENT'] });
        })
      );
    } else {
      this.saveOrder();
    }
  }
  saveOrder() {
    const nullValue = getNullValueInData(this.dataStore.data, this.MANDATORY_HEADER_COLUMNS);
    if (
      !nullValue &&
      this.dataStore.data['C_BPartner_ID'] != null &&
      this.dataStore.data['C_BPartner_Location_ID'] instanceof Object &&
      this.dataStore.data['AD_Org_ID'] &&
      this.dataStore.data['AD_Org_ID'].id > 0
    ) {
      if (this.dataStore.data['C_DocType_ID'] !== this.dataStore.data['C_DocTypeTarget_ID']) {
        this.dataStore.data['C_DocType_ID'] = this.dataStore.data['C_DocTypeTarget_ID'];
      }
      if (this.dataStore.data['POReference']) {
        /* this.store.getDataGrid(getOrderPORefRequest(this.dataStore.data['C_BPartner_ID'].id, this.dataStore.data['POReference'],this.dataStore.data['C_Order_ID'] ? this.dataStore.data['C_Order_ID'].id : 0), true).subscribe(res => {
          if (res && res.data && res.data.length > 0) {
            const ndate = moment(res.data[0]['DateOrdered']).format('DD/MM/YYYY');
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.warning'),
                this.getMessage('SZ_FastOrder_01') + ' ' + res.data[0]['DocumentNo'] + ' - ' + ndate ,
                'warning'
              )
            );
          }
      });*/
        const DocType_request: DataStoreRequest = {
          windowId: null,
          parent_constraint: null,
          compiereRequest: {
            startRow: 0,
            tableName: 'C_DocType',
            filterModel: {
              C_DocType_ID: {
                filterType: CompiereDataGridFilterType.SET,
                values: [this.dataStore.data['C_DocType_ID'].id],
                operators: [OperatorFilterType.EQUALS]
              }
            }
          }
        };
        this.store.getDataGrid(DocType_request).subscribe((res) => {
          if (res && res.data) {
            if (res.data[0]['XX_ISBPCTRLPOREF'] === 'Y') {
              this.store
                .getDataGrid(
                  getOrderPORefRequestDuplicateBP(
                    this.dataStore.data['C_BPartner_ID'].id,
                    this.dataStore.data['POReference'],
                    this.dataStore.data['C_Order_ID'] ? this.dataStore.data['C_Order_ID'].id : 0,
                    this.dataStore.data['C_DocType_ID'].id
                  ),
                  true
                )
                .subscribe((result1) => {
                  if (result1 && result1.data && result1.data.length > 0) {
                    let documentNoList = '';
                    result1.data.forEach((line) => {
                      documentNoList += line['DocumentNo'] + '  ';
                    });
                    this.messageManager.newMessage(
                      new IupicsMessage(
                        this.translateService.instant('generic.warning'),
                        this.getMessage('SZ_FastOrder_02') + ' ' + documentNoList,
                        'warning'
                      )
                    );
                  }
                });
            } else {
              this.store
                .getDataGrid(
                  getOrderPORefRequestDuplicateDT(
                    this.dataStore.data['POReference'],
                    this.dataStore.data['C_Order_ID'] ? this.dataStore.data['C_Order_ID'].id : 0,
                    this.dataStore.data['C_DocType_ID'].id
                  ),
                  true
                )
                .subscribe((result2) => {
                  if (result2 && res.data && result2.data.length > 0) {
                    let documentNoList = '';
                    result2.data.forEach((line) => {
                      documentNoList += line['DocumentNo'] + '  ';
                    });

                    this.messageManager.newMessage(
                      new IupicsMessage(
                        this.translateService.instant('generic.warning'),
                        this.getMessage('SZ_FastOrder_03') + ' ' + documentNoList,
                        'warning'
                      )
                    );
                  }
                });
            }
          }
        });
      }
      const id =
        this.dataStore.data['C_Order_ID'] instanceof Object
          ? this.dataStore.data['C_Order_ID'].id
          : this.dataStore.data['C_Order_ID'];
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.subscription = undefined;
      }
          this.subscription = this.saveOrderInStore(this.dataStore, id).subscribe();
    }
  }

  saveOrderLineInStore(datastore: DataStore, id?: number) {
    const tmpStore = new DataStore();
    tmpStore.data = this.getCurrentContext();
    // 150055
    tmpStore.data['M_PriceList_Version_ID']  = Number(tmpStore.data['M_PriceList_Version_ID']);
    datastore.data['M_PriceList_Version_ID']  = Number(datastore.data['M_PriceList_Version_ID']);
    //
    const dataReformat = Object.assign({}, this.getCurrentContext(datastore, tmpStore));
    Object.keys(dataReformat).forEach((key) => {
      if (dataReformat[key] instanceof Object) {
        dataReformat[key] = dataReformat[key].id;
      }
    });
    return this.poService.save('C_OrderLine', dataReformat, id);
  }
  saveOrderLine(lineDataStore: DataStore) {
    const nullValue = getNullValueInData(lineDataStore.data, this.MANDATORY_LINE_COLUMNNAMES);
    if ( 
        nullValue == null
      ) {
        console.log("No mandatory data null");
        console.log("QtyEntered : "+lineDataStore.data['QtyEntered']);
        const isNew = lineDataStore.data['C_OrderLine_ID'] == null;
        const id =
          lineDataStore.data['C_OrderLine_ID'] instanceof Object
            ? lineDataStore.data['C_OrderLine_ID'].id
            : lineDataStore.data['C_OrderLine_ID'];
        const sub$ = this.saveOrderLineInStore(lineDataStore, id).subscribe((result) => {
        // if new we should use callout again because record_id is needed
        lineDataStore.data['C_OrderLine_ID'] = result['C_OrderLine_ID'];
        if (isNew) {
          if (lineDataStore.data['M_Product_ID'] instanceof Object) {
            this.calloutColumnLine('M_Product_ID', lineDataStore);
          } else if (lineDataStore.data['C_Charge_ID'] instanceof Object) {
            this.calloutColumnLine('C_Charge_ID', lineDataStore);

         } else if (lineDataStore.data['IsDescription']) {
          this.calloutColumnLine('IsDescription', lineDataStore);
       }
       } else {
          this.store.syncDataChanges(lineDataStore, result, false, true);
        }
        this.refreshOrder(true);
        sub$.unsubscribe();
      });
    }
    else{
      console.log("Mandatory data null");
      if (lineDataStore.data['QtyEntered'] != null && lineDataStore.data['QtyEntered']>=0){
        const isNew = lineDataStore.data['C_OrderLine_ID'] == null;
        const id =
          lineDataStore.data['C_OrderLine_ID'] instanceof Object
            ? lineDataStore.data['C_OrderLine_ID'].id
            : lineDataStore.data['C_OrderLine_ID'];
        const sub$ = this.saveOrderLineInStore(lineDataStore, id).subscribe((result) => {
        // if new we should use callout again because record_id is needed
        lineDataStore.data['C_OrderLine_ID'] = result['C_OrderLine_ID'];
        if (isNew) {
          if (lineDataStore.data['M_Product_ID'] instanceof Object) {
            this.calloutColumnLine('M_Product_ID', lineDataStore);
          } else if (lineDataStore.data['C_Charge_ID'] instanceof Object) {
            this.calloutColumnLine('C_Charge_ID', lineDataStore);

         } else if (lineDataStore.data['IsDescription']) {
          this.calloutColumnLine('IsDescription', lineDataStore);
       }
       } else {
          this.store.syncDataChanges(lineDataStore, result, false, true);
        }
        this.refreshOrder(true);
        sub$.unsubscribe();
      });
      } else{ // affichage dans le console tous les fields obligatoires et non remplis */
        this.MANDATORY_LINE_COLUMNNAMES.forEach((columnName) => {
          const value = lineDataStore.data[columnName];
          console.log(columnName+" : "+value);
        });
      }
    }
  }
  notifyFromDataChange(item: any, vcrIndex?: number) {
    let lineDataStore = null;
    if (vcrIndex !== undefined) {
      lineDataStore = this.dataLines[vcrIndex];
    }
    if (item.data['columnName'] === 'C_BPartner_ID' && this.dataStore && this.dataStore.data['C_BPartner_ID']) {
      this.calloutPartner();
    } else if (
      this.CALLOUTS_HEADER_COLUMNNAMES.includes(item.data['columnName']) &&
      this.dataStore &&
      this.dataStore.data[item.data['columnName']]
    ) {
      this.calloutColumn(item.data['columnName']);
    }  else if (this.CALLOUTS_LINE_COLUMNNAMES.includes(item.data['columnName'])) {
      this.calloutColumnLine(item.data['columnName'], lineDataStore);
    }  else if (lineDataStore) {
      this.saveOrderLine(lineDataStore);
    } else if (this.HEADER_COLUMNS.includes(item.data['columnName'])) {
      if (this.saveBlocked) {
        this.messageManager.newMessage(
          new IupicsMessage(
            this.translateService.instant('generic.error'),
            this.translateService.instant('specificWindow.quickOrder.CompleteOrder'),
            'error'
          )
        );
      } else {
        this.saveOrder();
      }

    }
    if (item.data['columnName'] === 'C_Order_ID' && this.dataStore.data['C_Order_ID'] instanceof Object) {
      this.docStatus = this.translateService.instant('specificWindow.quickOrder.draft');
      this.newLine(true);
    }
    
  }

  /**
   * Permet d'ajouter visuellement une nouvelle ligne de commande + création d'un nouveau datastore
   */
  newLine(isEmpty = false) {
    if (
      isEmpty ||
      (this.dataStore &&
        this.dataStore.data['C_Order_ID'] &&
        this.dataStore.data['DocStatus'] &&
        this.dataStore.data['DocStatus'] !== 'CO' &&
        this.dataStore.data['DocStatus'].id !== 'CO' &&
        !this.dataLines.find((line) => !line.data['C_OrderLine_ID']))
    ) {
      this.nbLines.push(this.nbLines[this.nbLines.length - 1] + 1);
      this.dataLines.push(this.store.newSpecificWindowData(this.formId));
      this.dataLines[this.dataLines.length - 1].data['Line'] = this.dataLines.length * 10;
      this.lineMetaData.forEach((metaData) => {
        const compRef = this.createCustomDesignItem(metaData, true, this.dataLines.length - 1);
        if (compRef) {
          // make autocomplete overlay visible outside overflow
          compRef.instance['isInsideOverflow'] = true;
          if (metaData.columnName !== 'Description') {
            compRef.location.nativeElement.setAttribute('tabindex', this.tabindex++);
          } else {
            compRef.location.nativeElement.setAttribute('tabindex', -1);
          }
          if (metaData.columnName === 'M_Product_ID') {
            (<AutocompleteUiComponent>compRef.instance).data.isDefaultFocus = true;
            (<AutocompleteUiComponent>compRef.instance).data.IsFromForm = true;
          }
          this.dataContainers.pop();
          (<AbstractDataContainer>compRef.instance).data['fieldValueModifiedSub'].unsubscribe();
          (<AbstractDataContainer>compRef.instance).fieldValueModified.subscribe((dataStored) => {
            const item = this.specificData.items.find((specificItem) => {
              return metaData.type === CustomDesignItemType.FIELD
                ? metaData.columnName === specificItem.data.columnName
                : metaData.tableName === specificItem.name;
            });
            this.notifyFromDataChange(item, this.dataLines.indexOf(dataStored));
          });
          if (!this.dataContainersLine[this.dataLines.length - 1]) {
            this.dataContainersLine[this.dataLines.length - 1] = [];
          }
          this.dataContainersLine[this.dataLines.length - 1].push(<AbstractDataContainer>compRef.instance);

          (<AbstractDataContainer>compRef.instance).setNewData(this.dataLines[this.dataLines.length - 1]);
        }
      });
      this.gotoBottomOfLines();
    } else {
      this.checkMissingField();
    }
  }
  private gotoBottomOfLines() {
    setTimeout(() => {
      this.linesContainer.nativeElement.scrollTop =
        this.linesContainer.nativeElement.scrollHeight - this.linesContainer.nativeElement.clientHeight;
    }, 100);
  }
  checkMissingField(isAutoSave = false) {
    const nullValue = getNullValueInData(this.dataStore.data, this.MANDATORY_HEADER_COLUMNS);
    if (
      [
        'AD_Org_ID',
        'InvoiceRule',
        'M_PriceList_ID',
        'C_DocTypeTarget_ID',
        'C_PaymentTerm_ID',
        'PaymentRule',
        'DeliveryRule',
        'M_Warehouse_ID'
      ].includes(nullValue)
    ) {
      this.isSidebarOpen = true;
    }
    if (nullValue && !isAutoSave) {
      this.messageManager.newMessage(
        new IupicsMessage(
          this.translateService.instant('generic.warning'),
          this.translateService.instant('specificWindow.quickOrder.missingParams'),
          'warning'
        )
      );
    }
  }
  removeLine(lineDataStore: DataStore, index: number) {
    if (
      this.dataStore &&
      this.dataStore.data['DocStatus'] &&
      this.dataStore.data['DocStatus'] !== 'CO' &&
      this.dataStore.data['DocStatus'].id !== 'CO'
    ) {
      const id =
        lineDataStore.data['C_OrderLine_ID'] instanceof Object
          ? lineDataStore.data['C_OrderLine_ID'].id
          : lineDataStore.data['C_OrderLine_ID'];
      let obs = of(null);
      if (id) {
        obs = this.store.deleteWindowSpecificData('C_OrderLine', lineDataStore, id);
      }
      this.subscriptions.push(
        obs.subscribe((result) => {
          this.lineMetaData.forEach((metaData) => {
            this[metaData.vcr].toArray()[index].clear();
          });
          for (let i = index + 1; i < this.dataLines.length; i++) {
            this.lineMetaData.forEach((metaData) => {
              const element = (<ViewContainerRef>this[metaData.vcr].toArray()[i]).detach();
              (<ViewContainerRef>this[metaData.vcr].toArray()[i - 1]).insert(element);
            });
          }
          this.dataLines.splice(index, 1);
          this.dataContainersLine.splice(index, 1);
          this.nbLines.splice(-1);
          if (result) {
            this.refreshOrder(true);
          }
        })
      );
    }
  }
  processOrder() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (
      this.dataStore &&
      ((this.dataStore.data['DocStatus'] instanceof Object && this.dataStore.data['DocStatus'].id !== 'CO') ||
        this.dataStore.data['DocStatus'] === 'DR')
    ) {
      this.runWF();
    }
  }
  resetOrder() {
    this.isProcessed = false;
    this.updateButtonsReadOnly();
    this.resetlines();
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
    const ValueMWarehouse = this.dataStore.data['M_Warehouse_ID'];
    const ValueDocType = this.dataStore.data['C_DocTypeTarget_ID'];
    this.dataStore.dataChange.unsubscribe();
    this.dataStore = this.store.newSpecificWindowData(this.formId);
    this.dataContainers.forEach((dataContainer) => {
      if (['M_Warehouse_ID', 'C_DocTypeTarget_ID'].includes(dataContainer.data.columnName)) {
        dataContainer.data.defaultValue = null;
      }
      dataContainer.setNewData(this.dataStore);
    });
    this.dataContainers.forEach((dataContainer) => {
      if (dataContainer.data.columnName === 'M_Warehouse_ID') {
        dataContainer.updateStore(ValueMWarehouse.id);
      }
      if (dataContainer.data.columnName === 'C_DocTypeTarget_ID') {
        dataContainer.updateStore(ValueDocType.id);
      }
    });
    this.grandTotal = 0;
    this.grandVatTotal = 0;
    this.grandNetTotal = 0;
    this.chipsValue = [];
    this.nbUploadedFiles = 0;
    this.docStatus = this.translateService.instant('specificWindow.quickOrder.new');
  }
  updateHeader() {
    if (this.dataStore && this.dataStore.data) {
      this.grandTotal = this.dataStore.data['GrandTotal'];
      this.grandNetTotal = this.dataStore.data['TotalLines'];
      this.updateTaxAmount();
    }
  }
  saveOrderInStore(datastore: DataStore, id?: number, sync = true) {
    const dataReformat = Object.assign({}, datastore.data);
    Object.keys(dataReformat).forEach((key) => {
      if (dataReformat[key] instanceof Object) {
        dataReformat[key] = dataReformat[key].id;
      }
    });
    return this.poService.save('C_Order', dataReformat, id).pipe(
      switchMap((result) => {
        // joinfile need dskey to get back oldcurrentstore info during taggedcolumns generation
        const orderId = result['C_Order_ID'] instanceof Object ? result['C_Order_ID'].id : result['C_Order_ID'];
        const oldKey = datastore.key;
        this.dsKey = {
          parentId: 'quickCreateOrder',
          recordId: `C_Order_ID,${orderId}`,
          tabId: this.orderTabID,
          windowId: this.windowID
        };
        datastore.key = this.dsKey;
        // Set data_uuid needed for joinfile
        datastore.data['Data_UUID'] = `C_Order_ID,${orderId}`;
        result['Data_UUID'] = `C_Order_ID,${orderId}`;
        this.store.copyWindowDataToOldStore(datastore);
        datastore.key = null;
        if (sync) {
          this.store.syncDataChanges(datastore, result, false, true);
        } else {
          datastore.data = EditViewUtils.mergeCurrentDataDeepCopy(datastore.data, result);
        }
        datastore.key = oldKey;
        this.updateButtonsReadOnly();
        return of(true);
      }),
      catchError(() => {
        return of(false);
      })
    );
  }
  deleteDoc() {
    this.updateLoading(true);
    const orderId =
      this.dataStore.data['C_Order_ID'] instanceof Object
        ? this.dataStore.data['C_Order_ID'].id
        : this.dataStore.data['C_Order_ID'];
    let obs = of([]);
    const xx_cmsn_ids = this.chipsValue.map((chip) => {
      return { XX_CMSN_Calculated_ID: chip.XX_CMSN_Calculated_ID };
    });
    if (xx_cmsn_ids.length > 0) {
      obs = this.dataService.deleteData(this.salesRepTabID, xx_cmsn_ids).pipe(
        catchError(() => {
          return of(null);
        })
      );
    }
    return obs.pipe(
      switchMap((results) => {
        // CHECK ERROR DURING XX_CMSN_CALCULATED DELETE
        let hasXX_CMSN_CalculatedError = false;
        if (results) {
          if (results.length > 0) {
            hasXX_CMSN_CalculatedError =
              results.find((result) => result.messages.find((msg) => msg.type === 'ERROR')) !== undefined;
          }
        } else {
          hasXX_CMSN_CalculatedError = true;
        }
        return of(!hasXX_CMSN_CalculatedError);
      }),
      switchMap((isSuccess) => {
        if (isSuccess) {
          // DELETE ORDER
          return this.dataService.deleteData(this.orderTabID, [{ C_Order_ID: orderId }]).pipe(
            map((results) => {
              let hasOrderError = false;
              if (results) {
                if (results.length > 0) {
                  hasOrderError = results.find((result) => result.messages.find((msg) => msg.type !== 'SUCCESS')) !== undefined;
                }
              } else {
                hasOrderError = true;
              }
              return of(!hasOrderError);
            }),
            catchError(() => {
              return of(false);
            })
          );
        } else {
          return of(false);
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }
  cancelDoc() {
    if (this.dataStore && this.dataStore.data && this.dataStore.data['C_Order_ID']) {
      this.updateLoading(true);
      this.subscriptions.push(
        this.deleteDoc().subscribe((deleted) => {
          this.updateLoading(false);
          if (deleted) {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.success'),
                this.translateService.instant('specificWindow.quickOrder.orderDeleted'),
                'success'
              )
            );
            this.resetOrder();
          } else {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.error'),
                this.translateService.instant('specificWindow.quickOrder.errorOrderDeleted'),
                'error'
              )
            );
          }
        })
      );
    } else {
      this.resetOrder();
    }
  }
  resetlines(nbLines = [1]) {
    // Reset lines
    this.dataLines.forEach((dataLine, index) => {
      this.lineMetaData.forEach((metaData) => {
        this[metaData.vcr].toArray()[index].clear();
        this[metaData.vcr].toArray()[index].remove();
      });
    });
    this.dataLines = [];
    this.nbLines = nbLines;
    this.dataContainersLine = [];
  }
  getCreatedOrderLines() {
    const orderId =
      this.dataStore.data['C_Order_ID'] instanceof Object
        ? this.dataStore.data['C_Order_ID'].id
        : this.dataStore.data['C_Order_ID'];
    return this.store.getDataGrid(getOrderLineRequest(orderId, this.ad_language), true).pipe(
      switchMap((response) => {
        if (response && response.data) {
          let l = Array.from({ length: response.data.length + 1 }, (_, i) => i + 1);
          this.resetlines(l);
          setTimeout(() => {
            response.data
              .sort((l1, l2) => l1['Line'] - l2['Line'])
              .forEach((lineData) => {
                const lineDataStore = this.store.newSpecificWindowData(this.formId, this.dataStore);
                lineDataStore.data = lineData;
                this.dataLines.push(lineDataStore);
                this.lineMetaData.forEach((metaData) => {
                  const compRef = this.createCustomDesignItem(metaData, true, this.dataLines.length - 1, lineDataStore);
                  // make autocomplete overlay visible outside overflow
                  compRef.instance['isInsideOverflow'] = true;
                  if (compRef) {
                    if (metaData.columnName !== 'Description') {
                      compRef.location.nativeElement.setAttribute('tabindex', this.tabindex++);
                    } else {
                      compRef.location.nativeElement.setAttribute('tabindex', -1);
                    }
                    this.dataContainers.pop();
                    (<AbstractDataContainer>compRef.instance).data['fieldValueModifiedSub'].unsubscribe();
                    (<AbstractDataContainer>compRef.instance).fieldValueModified.subscribe((dataStored) => {
                      const item = this.specificData.items.find((specificItem) => {
                        return metaData.type === CustomDesignItemType.FIELD
                          ? metaData.columnName === specificItem.data.columnName
                          : metaData.tableName === specificItem.name;
                      });
                      this.notifyFromDataChange(item, this.dataLines.indexOf(dataStored));
                    });
                    if (!this.dataContainersLine[this.dataLines.length - 1]) {
                      this.dataContainersLine[this.dataLines.length - 1] = [];
                    }
                    this.dataContainersLine[this.dataLines.length - 1].push(<AbstractDataContainer>compRef.instance);

                    (<AbstractDataContainer>compRef.instance).isSetDefaultValue = true;
                    (<AbstractDataContainer>compRef.instance).setNewData(lineDataStore);
                  }
                });
                this.store.syncDataChanges(lineDataStore, lineData);
              });
          }, 1000);
          return of(true);
        } else {
          return of(false);
        }
      }),
      catchError((err) => {
        return of(false);
      })
    );
  }
  refreshOrder(onlyHeader = false) {
    this.updateLoading(true);
    const orderId =
      this.dataStore.data['C_Order_ID'] instanceof Object
        ? this.dataStore.data['C_Order_ID'].id
        : this.dataStore.data['C_Order_ID'];
    let obs = of(true);
    if (!onlyHeader) {
      obs = this.getCreatedOrderLines();
    }
    obs
      .pipe(
        switchMap((getLineSuccess) => {
          if (getLineSuccess) {
            return this.store.getDataGrid(getOrderRequest(orderId, this.ad_language));
          } else {
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('generic.error'),
                this.translateService.instant('specificWindow.quickOrder.orderLineNotFound'),
                'error'
              )
            );
            return of(null);
          }
        }),
        catchError(() => {
          return of(null);
        })
      )
      .subscribe((orderData) => {
        this.updateLoading(false);
        if (orderData) {
          this.docStatus = orderData.data[0]['DocStatus'].displayValue;
          this.store.syncDataChanges(this.dataStore, orderData.data[0], false, true);
          this.updateHeader();
          this.updateButtonsReadOnly();
        } else {
          this.messageManager.newMessage(
            new IupicsMessage(
              this.translateService.instant('generic.error'),
              this.translateService.instant('specificWindow.quickOrder.orderNotFound'),
              'error'
            )
          );
        }
      });
  }
  refreshSalesRep() {
    this.updateLoading(true);
    const orderId =
      this.dataStore.data['C_Order_ID'] instanceof Object
        ? this.dataStore.data['C_Order_ID'].id
        : this.dataStore.data['C_Order_ID'];
    this.subscriptions.push(
      this.store.getDataGrid(getSalesRepRequest(orderId, this.ad_language)).subscribe((response) => {
        this.chipsValue = [];
        if (response && response.data) {
          response.data.forEach((line) => {
            this.chipsValue.push({ XX_CMSN_Calculated_ID: line['XX_CMSN_CALCULATED_ID'], name: line['NAME'] });
          });
        }
        this.updateLoading(false);
        this.updateButtonsReadOnly();
      })
    );
  }
  updateNbFileAttached() {
    const samples = this.createDocQueryInfo();
    const sub = this.docserverService.getNbDocuments(samples, MongoSearchQueryOperator.OR).subscribe((nb) => {
      this.nbUploadedFiles = nb;
      sub.unsubscribe();
    });
  }
  createDocQueryInfo() {
    const recordId = this.dsKey.recordId.split(',');
    const filterFromKey = this.docserverService.createfilterFromKey(this.dsKey);

    let samples: any[] = [
      {
        ...filterFromKey,
        'META|TABLE_ID': this.tableID,
        'META|RECORD_ID': recordId.length > 1 ? (isNaN(parseInt(recordId[1], 10)) ? recordId[1] : parseInt(recordId[1], 10)) : -1
      }
    ];
    // On check les colonnes indiquées dans la config comme nécessitant la récup d'attachment
    if (this.data) {
      const docFilters = this.data.docFilters;
      if (docFilters) {
        samples = samples.concat(this.docserverService.createDocFiltersInfo(docFilters, this.dsKey));
      }
    }
    return samples;
  }
  updateTaxAmount() {
    this.grandVatTotal = 0;
    const orderId =
      this.dataStore.data['C_Order_ID'] instanceof Object
        ? this.dataStore.data['C_Order_ID'].id
        : this.dataStore.data['C_Order_ID'];
    this.subscriptions.push(
      this.store.getDataGrid(getTaxOrderGroupRequest(orderId, this.orderTaxTabID), true).subscribe((response) => {
        if (response && response.data) {
          response.data.forEach((taxGroup) => {
            this.grandVatTotal += taxGroup['TaxAmt'];
          });
        }
        this.grandVatTotal = Math.round((this.grandVatTotal + Number.EPSILON) * 100) / 100;
      })
    );
  }

  // WORKFLOW
  runWF() {
    const dataStored = this.dataStore;
    if (dataStored && dataStored.data && dataStored.data['C_DocTypeTarget_ID'] && dataStored.data['C_DocTypeTarget_ID'].id > 0) {
      const DocType_request: DataStoreRequest = {
        windowId: null,
        parent_constraint: null,
        compiereRequest: {
          startRow: 0,
          tableName: 'C_DocType',
          filterModel: {
            C_DocType_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [dataStored.data['C_DocTypeTarget_ID'].id],
              operators: [OperatorFilterType.EQUALS]
            }
          }
        }
      };
      this.store.getDataGrid(DocType_request).subscribe((res) => {
        if (res && res.data && res.data[0]['ISPRINTEDPROCESSING'] === 'Y' && res.data[0]['DOCBASETYPE'] === 'SOO') {
          this.updateModalDisplay(
            { key: 'displayFormUI', value: true, sourceComponent: this },
            { key: 'formId', value: this.printZoneFormID }
          );
        } else {
          this.runWFExecute();
        }
      });
    }
  }

  runWFExecute() {
    let data = {};
    /*On ajoute le contexte utilisateur */
    const userContext = this.connectorService.getIupicsUserContext();
    const keys = Object.keys(userContext);
    keys.forEach((key) => {
      data[key] = cloneDeep(userContext[key]);
    });
    /*on merge le contexte avec le store du composant concerné et le contexte utilisateur */
    if (this.dataStore && this.dataStore.data) {
      data = EditViewUtils.mergeCurrentDataDeepCopy(data, this.dataStore.data);
    }
    this.updateLoading(true);
    const orderId =
      this.dataStore.data['C_Order_ID'] instanceof Object
        ? this.dataStore.data['C_Order_ID'].id
        : this.dataStore.data['C_Order_ID'];
    this.subscription = this.workflowService
      .runWF({
        record_id: String(orderId),
        windowCtx: data,
        action: 'CO',
        table_Name: 'C_Order',
        table_id: this.tableID,
        ad_process_id: '104'
      })
      .subscribe(
        (response) => {
          this.updateLoading(false);
          if (response) {
            if (response.Success === true) {
            } else {
              this.messageManager.newMessage(
                new IupicsMessage(this.translateService.instant('generic.warning'), response.Message)
              );
            }
            if (response.Success === true) {
              const initialStatus = this.dataStore.data['DocStatus'].id
                ? this.dataStore.data['DocStatus'].id
                : this.dataStore.data['DocStatus'];
              this.dataStore.data['DocStatus'] = response.DocStatus;
              this.isProcessed = response.DocStatus.id === 'CO';
              // check if some warning are sent
              if ((response.Processed === 'N' && response.Message) || initialStatus == response.DocStatus.id) {
                this.messageManager.newMessage(
                  new IupicsMessage(this.translateService.instant('generic.warning'), response.Message, 'warning')
                );
              }
              this.refreshOrder();
              if (this.XX_PrintZone_ID != null) {
                const Process_request: DataStoreRequest = {
                  windowId: null,
                  parent_constraint: null,
                  compiereRequest: {
                    startRow: 0,
                    tableName: 'AD_Process',
                    filterModel: {
                      Value: {
                        filterType: CompiereDataGridFilterType.SET,
                        values: ['CompleteOrderPrintProcess'],
                        operators: [OperatorFilterType.EQUALS]
                      }
                    }
                  }
                };
                this.store.getDataGrid(Process_request).subscribe((res) => {
                  if (res && res.data && res.data[0]['AD_PROCESS_ID'] > 0) {
                    const paramsMap: ProcessParams = {
                      ad_process_id: res.data[0]['AD_PROCESS_ID'],
                      className: null,
                      record_id: parseInt(String(this.dsKey.recordId.split(',').pop()), 10),
                      tableName: 'C_Order',
                      tables: null,
                      params: {},
                      ad_tab_id: null,
                      windowCtx: this.getCurrentContext()
                    };
                    paramsMap.params['XX_PrintZone_ID'] = this.XX_PrintZone_ID;
                    paramsMap.params['AD_Table_ID'] = this.tableID;

                    this.subscriptions.push(this.uiCreator.executeProcess(paramsMap).subscribe((values) => {
                      this.saveBlocked = true;
                      this.updateLoading(true);
                      const progressSub = this.progressService.watchProcessInProgress().subscribe((pings) => {
                        const me = this.connectorService.getIupicsUserAccount();
                        const ping = pings.find((p) => {
                          return p.AD_User_ID.id === me.id && p.AD_Process_ID.id === res.data[0]['AD_PROCESS_ID'];
                        });
                        if (ping && ping.Status === 'finish') {
                          this.saveBlocked = false;
                          this.updateLoading(false);
                          progressSub.unsubscribe();   
                        }
                      });
                    }));
                  }
                  this.XX_PrintZone_ID = null;
                });
              }
            } else {
              this.messageManager.newMessage(
                new IupicsMessage(this.translateService.instant('generic.warning'), response.Message || ' no response')
              );
            }
          }
          this.subscription.unsubscribe();
        },
        () => {
          this.updateLoading(false);
        }
      );
  }

  runWFPrintZone(printZone) {
    if (printZone && printZone.id > 0) {
      this.XX_PrintZone_ID = printZone.id;
    } else {
      this.XX_PrintZone_ID = -1;
    }
    this.runWFExecute();
  }
  // END WORKFLOW

  initAD_Data() {
    this.translations = {
      line: this.translateService.instant('specificWindow.quickOrder.lineHeader.line'),
      isDescription: this.translateService.instant('specificWindow.quickOrder.lineHeader.isDescription'),
      charge: this.translateService.instant('specificWindow.quickOrder.lineHeader.charge'),
      product: this.translateService.instant('specificWindow.quickOrder.lineHeader.product'),
      description: this.translateService.instant('specificWindow.quickOrder.lineHeader.description'),
      priceActual: this.translateService.instant('specificWindow.quickOrder.lineHeader.priceActual'),
      priceList: this.translateService.instant('specificWindow.quickOrder.lineHeader.priceList'),
      quantity: this.translateService.instant('specificWindow.quickOrder.lineHeader.quantity'),
      priceEntered: this.translateService.instant('specificWindow.quickOrder.lineHeader.priceEntered'),
      uom: this.translateService.instant('specificWindow.quickOrder.lineHeader.uom'),
      qtyunit: this.translateService.instant('specificWindow.quickOrder.lineHeader.qtyunit'),
      xUom: this.translateService.instant('specificWindow.quickOrder.lineHeader.xUom'),
      discount: this.translateService.instant('specificWindow.quickOrder.lineHeader.discount'),
      totalLine: this.translateService.instant('specificWindow.quickOrder.lineHeader.totalLine'),
      tax: this.translateService.instant('specificWindow.quickOrder.lineHeader.tax'),
      trialphasedays: this.translateService.instant('specificWindow.quickOrder.lineHeader.trialphasedays'),
      groupLine: this.translateService.instant('specificWindow.quickOrder.lineHeader.groupLine'),
      XX_IsNetPrice: this.translateService.instant('specificWindow.quickOrder.lineHeader.XX_IsNetPrice'),
      XX_RelatedProduct: this.translateService.instant('specificWindow.quickOrder.lineHeader.XX_RelatedProduct')
    };
    this.tableID = 314;
    this.orderTabID = 186;
    this.orderLineTabID = 187;
    this.salesRepTabID = 1000584;
    this.orderTaxTabID = 236;
    this.windowID = 183;
    this.subscriptions.push(
      this.store.getDataGrid(getLogMessageRequest(this.ad_language), true).subscribe((results) => {
        this.messages = results.data as any[];
      })
    );
    return this.store.getDataGrid(getTabDataRequest(), true).pipe(
      switchMap((response) => {
        if (!this.data) {
          this.data = {};
        }
        if (response && response.data) {
          // TABLE TAB INIT
          const salesRepLineTab = response.data.find((tab) => tab['TABLENAME'] === 'XX_CMSN_Calculated');
          const orderLineTab = response.data.find((tab) => tab['TABLENAME'] === 'C_OrderLine');
          const orderTab = response.data.find((tab) => tab['TABLENAME'] === 'C_Order');
          const orderTaxTab = response.data.find((tab) => tab['TABLENAME'] === 'C_OrderTax');
          if (salesRepLineTab) {
            this.salesRepTabID = salesRepLineTab['AD_TAB_ID'];
          }
          if (orderLineTab) {
            this.orderLineTabID = orderLineTab['AD_TAB_ID'];
          }
          if (orderTaxTab) {
            this.orderTaxTabID = orderTaxTab['AD_TAB_ID'];
          }
          if (orderTab) {
            this.data.docFilters = orderTab['DOCFILTERS'];
            this.data.taggedColumns = orderTab['TAGGEDCOLUMNS'];
            this.tableID = orderTab['AD_TABLE_ID'];
            this.orderTabID = orderTab['AD_TAB_ID'];
            this.windowID = orderTab['AD_WINDOW_ID'];
          }
        }
        return of(response);
      }),
      switchMap((response) => {
        // HEADER CALLOUT FIELDS
        return this.store.getDataGrid(getCalloutFieldIds(this.orderTabID, this.CALLOUTS_HEADER_COLUMNNAMES));
      }),
      switchMap((response) => {
        if (response && response.data) {
          this.calloutFields = {};
          response.data.forEach((col) => {
            this.calloutFields[col['COLUMNNAME']] = col['AD_FIELD_ID'];
          });
        }
        return of(response);
      }),
      switchMap((response) => {
        // LINE CALLOUT FIELDS
        return this.store.getDataGrid(getCalloutFieldIds(this.orderLineTabID, this.CALLOUTS_LINE_COLUMNNAMES));
      }),
      switchMap((response) => {
        if (response && response.data) {
          response.data.forEach((col) => {
            this.calloutFields[col['COLUMNNAME']] = col['AD_FIELD_ID'];
          });
        }
        return of(response);
      }),
      switchMap((response) => {
        // LINE CALLOUT FIELDS
        return this.store.getDataGrid(getFormID());
      }),
      switchMap((response) => {
        if (response && response.data && response.data[0]) {
          this.printZoneFormID = response.data[0]['AD_FORM_ID'];
        }
        return of(true);
      })
    );
  }
  updateButtonsReadOnly() {
    this.dataContainers.forEach((comp) => {
      if (comp instanceof ButtonUiComponent && comp.data.columnName !== 'DocAction') {
        comp.updateReadOnly(this.dataStore, comp.data.columnName);
      }
    });
  }
  /**
   * récupération du contexte complet du composant
   * @param dataStore nouveau datastore à prendre en compte
   */

  getMessage(value: string) {
    const msgFound = this.messages.find((msg) => msg.Value === value);
    let msg = value;
    if (msgFound) {
      msg = msgFound.MsgText;
    }
    return msg;
  }

  getCurrentContext(dataStore?: DataStore, dataStoreParent?: DataStore) {
    let specificParent;
    let editViewParent;
    if (this.parentComponent && this.parentComponent.editTabs && this.parentComponent.editTabs[0]) {
      editViewParent = this.parentComponent;
    } else if (this.parentComponent instanceof SpecificWindowUiComponent) {
      specificParent = this.parentComponent;
    }
    /*merge avec le contexte du parent */
    if (dataStoreParent) {
      return EditViewUtils.mergeCurrentDataDeepCopy(
        cloneDeep(dataStore ? dataStore.data : this.dataStore.data),
        cloneDeep(dataStoreParent ? dataStoreParent.data : this.dataStore.data)
      );
    }
    if (specificParent) {
      const specificParentContext = specificParent.getCurrentContext();
      return EditViewUtils.mergeCurrentDataDeepCopy(
        cloneDeep(dataStore ? dataStore : this.dataStore.data),
        specificParentContext
      );
    } else {
      return EditViewUtils.getCurrentContext(
        editViewParent,
        dataStore ? dataStore : this.dataStore,
        this.connectorService.getIupicsUserContext()
      );
    }
  }
  /**
   * Permet d'être notifié qu'un datacontainer est complètement construit
   * @param datacontainer datacontainer qui est passé dans son afterViewInit
   */
   notifyFromDatacontainerInit(datacontainer: AbstractDataContainer) {
    if (this.dataContainers.includes(datacontainer)) {
       if (datacontainer instanceof ButtonUiComponent ) {
      datacontainer.updateReadOnly(this.dataStore, datacontainer.data.columnName);
    }
    }
  }
}

class ADMessage {
  Value: string;
  MsgText: string;
  constructor(value: string, msgText: string) {
    this.Value = value;
    this.MsgText = msgText;
  }
  //#endregion Model
}

export enum MongoSearchQueryOperator {
  EQUALS = 'eq',
  NOT_EQUALS = 'ne',
  GREATER_THAN = 'gt',
  LESS_THAN = 'lt',
  GREATER_THAN_EQUALS = 'gte',
  LESS_THAN_EQUALS = 'lte',
  IN = 'in',
  NIN = 'nin',
  REGEX = 'regex',
  EXIST = 'exists',
  AND = 'and',
  OR = 'or',
}