import {
  CompiereDataGridFilterType,
  CompiereDataGridRequestJSON,
  CompiereDataGridType,
} from '@compiere-ws/models/compiere-data-json';
import { ColDataRequest, FilterModel, SortModel } from '@iupics/apiz-grid';

export enum FilterLink {
  OR = 'or',
  AND = 'and',
}

export interface FilterOperator {
  id: number;
  filterType: CompiereDataGridFilterType;
  label: string;
  type: OperatorFilterType;
  isRange: boolean;
}

export class UniversalFilterWS {
  windowType: CompiereDataGridType;
  entityId: number;
  filterModel: {
    [columnName: string]: FilterModel;
  };
  sortModel: SortModel[];
  rowGroupCols?: Omit<ColDataRequest, 'colId'>[];
  valueCols?: ColDataRequest[];
  pivotMode?: boolean;

  constructor(uf: CompiereDataGridRequestJSON) {
    this.windowType = uf.windowType;
    this.entityId = uf.entityId;
    this.filterModel = uf.filterModel;
    this.sortModel = uf.sortModel;
    this.rowGroupCols = uf.rowGroupCols;
    this.valueCols = uf.valueCols;
  }
}

export enum OperatorFilterType {
  LESS = 'lessThan',
  LESSTHANOREQUAL = 'lessThanOrEqual',
  BIGGER = 'biggerThan',
  BIGGERTHANOREQUAL = 'biggerThanOrEqual',
  BETWEEN = 'between',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notContains',
  EQUALS = 'equals',
  NOT_EQUALS = 'notEqual',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
  IS_NULL = 'isNull',
  IS_NOT_NULL = 'isNotNull',
}

let i = 0;
export const filterOperators: FilterOperator[] = [
  // DATE
  // #START CUSTO-SAMVAZ
  { id: i++, filterType: CompiereDataGridFilterType.DATE, label: '=', type: OperatorFilterType.EQUALS, isRange: false },
  // #END CUSTO-SAMVAZ
  { id: i++, filterType: CompiereDataGridFilterType.DATE, label: '<', type: OperatorFilterType.LESS, isRange: false },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.DATE,
    label: '<=',
    type: OperatorFilterType.LESSTHANOREQUAL,
    isRange: false,
  },
  { id: i++, filterType: CompiereDataGridFilterType.DATE, label: '>', type: OperatorFilterType.BIGGER, isRange: false },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.DATE,
    label: '>=',
    type: OperatorFilterType.BIGGERTHANOREQUAL,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.DATE,
    label: '!=',
    type: OperatorFilterType.NOT_EQUALS,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.DATE,
    label: 'universalFilter.filterLabels.between',
    type: OperatorFilterType.BETWEEN,
    isRange: true,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.DATE,
    label: 'universalFilter.filterLabels.isNull',
    type: OperatorFilterType.IS_NULL,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.DATE,
    label: 'universalFilter.filterLabels.isNotNull',
    type: OperatorFilterType.IS_NOT_NULL,
    isRange: false,
  },
  // TIME
  { id: i++, filterType: CompiereDataGridFilterType.TIME, label: '<', type: OperatorFilterType.LESS, isRange: false },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TIME,
    label: '<=',
    type: OperatorFilterType.LESSTHANOREQUAL,
    isRange: false,
  },
  { id: i++, filterType: CompiereDataGridFilterType.TIME, label: '>', type: OperatorFilterType.BIGGER, isRange: false },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TIME,
    label: '>=',
    type: OperatorFilterType.BIGGERTHANOREQUAL,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TIME,
    label: '!=',
    type: OperatorFilterType.NOT_EQUALS,
    isRange: false,
  },
  { id: i++, filterType: CompiereDataGridFilterType.TIME, label: '=', type: OperatorFilterType.EQUALS, isRange: false },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TIME,
    label: 'universalFilter.filterLabels.between',
    type: OperatorFilterType.BETWEEN,
    isRange: true,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TIME,
    label: 'universalFilter.filterLabels.isNull',
    type: OperatorFilterType.IS_NULL,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TIME,
    label: 'universalFilter.filterLabels.isNotNull',
    type: OperatorFilterType.IS_NOT_NULL,
    isRange: false,
  },
  // TEXT
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TEXT,
    label: 'universalFilter.filterLabels.contains',
    type: OperatorFilterType.CONTAINS,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TEXT,
    label: 'universalFilter.filterLabels.notContains',
    type: OperatorFilterType.NOT_CONTAINS,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TEXT,
    label: 'universalFilter.filterLabels.startsWith',
    type: OperatorFilterType.STARTS_WITH,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TEXT,
    label: 'universalFilter.filterLabels.endsWith',
    type: OperatorFilterType.ENDS_WITH,
    isRange: false,
  },
  { id: i++, filterType: CompiereDataGridFilterType.TEXT, label: '=', type: OperatorFilterType.EQUALS, isRange: false },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TEXT,
    label: '!=',
    type: OperatorFilterType.NOT_EQUALS,
    isRange: false,
  },
  { id: i++, filterType: CompiereDataGridFilterType.TEXT, label: '<', type: OperatorFilterType.LESS, isRange: false },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TEXT,
    label: '<=',
    type: OperatorFilterType.LESSTHANOREQUAL,
    isRange: false,
  },
  { id: i++, filterType: CompiereDataGridFilterType.TEXT, label: '>', type: OperatorFilterType.BIGGER, isRange: false },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TEXT,
    label: '>=',
    type: OperatorFilterType.BIGGERTHANOREQUAL,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TEXT,
    label: 'universalFilter.filterLabels.isNull',
    type: OperatorFilterType.IS_NULL,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.TEXT,
    label: 'universalFilter.filterLabels.isNotNull',
    type: OperatorFilterType.IS_NOT_NULL,
    isRange: false,
  },
  // NUMBER
  {
    id: i++,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: '=',
    type: OperatorFilterType.EQUALS,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: '!=',
    type: OperatorFilterType.NOT_EQUALS,
    isRange: false,
  },
  { id: i++, filterType: CompiereDataGridFilterType.NUMBER, label: '<', type: OperatorFilterType.LESS, isRange: false },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: '<=',
    type: OperatorFilterType.LESSTHANOREQUAL,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: '>',
    type: OperatorFilterType.BIGGER,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: '>=',
    type: OperatorFilterType.BIGGERTHANOREQUAL,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: 'universalFilter.filterLabels.between',
    type: OperatorFilterType.BETWEEN,
    isRange: true,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: 'universalFilter.filterLabels.isNull',
    type: OperatorFilterType.IS_NULL,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.NUMBER,
    label: 'universalFilter.filterLabels.isNotNull',
    type: OperatorFilterType.IS_NOT_NULL,
    isRange: false,
  },
  // SET
  { id: i++, filterType: CompiereDataGridFilterType.SET, label: '=', type: OperatorFilterType.EQUALS, isRange: false },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.SET,
    label: '!=',
    type: OperatorFilterType.NOT_EQUALS,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.SET,
    label: 'universalFilter.filterLabels.isNull',
    type: OperatorFilterType.IS_NULL,
    isRange: false,
  },
  {
    id: i++,
    filterType: CompiereDataGridFilterType.SET,
    label: 'universalFilter.filterLabels.isNotNull',
    type: OperatorFilterType.IS_NOT_NULL,
    isRange: false,
  },
];
