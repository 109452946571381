import { Injectable } from '@angular/core';
import { buffer, debounceTime, fromEvent, map } from 'rxjs';

@Injectable()
export class SKWScannerService {
  readonly CHAR_FLAG = '~';
  readonly SCAN_COMPLETE_TIMEOUT = 10; // milliseconds

  readonly #source = fromEvent<KeyboardEvent>(document, 'keypress');

  scanner = this.#source.pipe(
    buffer(this.#source.pipe(debounceTime(this.SCAN_COMPLETE_TIMEOUT))),
    map((events) => {
      let inBetweenFlags = false;
      let currentText = '';
      for (const { key } of events) {
        if (key === this.CHAR_FLAG) {
          inBetweenFlags = !inBetweenFlags;
        } else if (inBetweenFlags) {
          currentText += key;
        }
      }
      return currentText;
    })
  );
}
