import { TypeEnum } from '@iupics-util/models/types.util';
import { IAutocomplete } from '@iupics/apiz-grid';
import { Observable } from 'rxjs';

export type SKWPage = 'list' | 'inventory' | 'transfer' | 'new_transfer' | 'transfer_line';
export const SKWPage: TypeEnum<SKWPage> = {
  LIST: 'list',
  INVENTORY: 'inventory',
  TRANSFER: 'transfer',
  NEW_TRANSFER: 'new_transfer',
  TRANSFER_LINE: 'transfer_line'
};

export type SKWListTabType = 'tasks' | 'transfers';
export const SKWListTabType: TypeEnum<SKWListTabType> = {
  TASKS: 'tasks',
  TRANSFERS: 'transfers'
};

export type SKWTaskStatus = 'CO' | 'AS';
export const SKWTaskStatus: TypeEnum<SKWTaskStatus> = {
  AS: 'AS',
  CO: 'CO'
};

export type SKWLineStatus = 'TL' | 'UL';
export const SKWLineStatus: TypeEnum<SKWLineStatus> = {
  TL: 'TL',
  UL: 'UL'
};

type SKWTransferData = {
  AD_Client_ID?: {
    id: number;
    displayValue: string;
  };
  AD_Org_ID?: {
    id: number;
    displayValue: string;
  };
  AD_Process_ID?: {
    id: number;
    displayValue: string;
  };
  Description?: string;
  Comment?: string;
  Record_ID: number; // id
  table_id?: number;
  table_name?: string;
  Created?: string;
  CreatedBy?: {
    id: number;
    displayValue: string;
  };
  Updated?: string;
  DocStatus?: string;
  locator_destination_barcode?: string;
  locator_destination?: IAutocomplete;
  palette_destination?: number;
  palette_destination_barcode?: string;
};

export type SKWTransferHeaderData = SKWTransferData & {
  DocumentNo?: string;
  transferNO?: string;
  Priority?: string;
  Storekeeper_ID: number | null; // AD_User_ID
  EndDate?: string;
  isEX?: string;
  isOF?: string;
  isPalette?: string;
  isRA?: string;
  isCond?: string;
  nblineco?: number;
};

export type SKWTransferLineData = SKWTransferData & {
  transferNO?: string;
  Line?: number;
  Line_ID?: number;
  tableline_id: number;
  tableline_name: string;
  locator_source: IAutocomplete;
  locator_source_barcode?: string;
  palette_source?: number;
  palette_source_barcode?: string;
  M_Product_ID: IAutocomplete;
  product_barcode?: string;
  product_description?: string;
  Qty: number;
};

export type SKWTransferLineFormData = {
  locator: IAutocomplete;
  palette?: any; // IAutocomplete;
  Product: IAutocomplete;
  Qty: number;
};

export type SKWInventoryLine = {
  M_Locator_ID: IAutocomplete;
  M_Product_ID: IAutocomplete;
  Quantity: number;
};

//#region State
export type SKWStateActionType = 'refresh' | 'save' | 'add' | 'assign' | 'remove' | 'get' | 'get_more';

export const SKWStateActionType: TypeEnum<SKWStateActionType> = {
  REFRESH: 'refresh',
  SAVE: 'save',
  ADD: 'add',
  ASSIGN: 'assign',
  REMOVE: 'remove',
  GET: 'get',
  GET_MORE: 'get_more'
};

export type SKWStateAction = {
  type: SKWStateActionType;
  source?: Observable<Partial<SKWContextState> | undefined>;
  clearData?: boolean;
  after?: (state: SKWContextState) => void;
  isLoading: boolean;
};

export type SKWListData<T = SKWTransferHeaderData[]> = {
  tasks?: {
    CO?: T;
    AS?: T;
  };
  transfers?: T;
};

export type SKWListDataParams = SKWListData<{
  nextStartRow: number;
  endRow: number;
}>;

export type SKWDataListParams = {
  startRow: number;
  endRow: number;
  lastRow: number;
};

export type SKWContextStateData = {
  data: SKWListData | undefined;
  params: SKWListDataParams;
};

export type SKWContextState = SKWContextStateData & {
  pending: SKWStateActionType;
  loaded: boolean;
  error: string | undefined;
};
//#endregion

export type SKWFilter = {
  label: string;
  value: string;
};

export type SearchData = {
  line: SKWTransferLineData;
  data: Partial<SKWTransferLineFormData>;
};

export type SKWPalletForm = {
  XX_Pallet_ID: number;
  Storekeeper_ID: number;
  M_Locator_ID: number;
  Description: string;
};

export type SKWConditioningTransferForm = {
  M_Movement_ID: number;
  M_Locator_ID: number;
};
