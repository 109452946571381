<div class="content">
  <div class="title-bar">
    @if (isNewTransfer()) {
      <h3 class="title">{{ 'NewLine' | SKWTranslate }}</h3>
    } @else if (tabType() === 'TL') {
      <h3 class="title">{{ 'Loading' | SKWTranslate }}</h3>
    } @else {
      <h3 class="title">{{ 'Unloading' | SKWTranslate }}</h3>
    }

    @if (!isNewTransfer()) {
      <iu-storekeeper-button [label]="'Cancel' | SKWTranslate" (onClick)="cancelNewLine($event)" />
    }
  </div>

  <iu-storekeeper-window-input-scan [(data)]="inputData" (validation)="save($event)" />

  @if (tabType() === 'UL' || inputData()?.palette || inputData()?.Product) {
    <iu-storekeeper-input
      [type]="'text'"
      [label]="'Location' | SKWTranslate"
      [placeholder]="
        (tabType() === 'TL' ? data()?.locator_source?.displayValue : data()?.locator_destination?.displayValue) ?? ''
      "
      [value]="inputData()?.locator?.displayValue ?? ''"
      (valueChange)="changeValue('locator', $event)"
      [validation]="isLocatorCorrect()"
      [readOnly]="!isNewTransfer()"
    />
  }

  @if (isPalette() || (isNewTransfer() && !inputData()?.Product)) {
    <iu-storekeeper-input
      [type]="'text'"
      [label]="'Pallet' | SKWTranslate"
      [placeholder]="(tabType() === 'TL' ? data()?.palette_source_barcode : data()?.palette_destination_barcode) ?? ''"
      [value]="inputData()?.palette?.displayValue ?? ''"
      (valueChange)="changeValue('palette', $event)"
      [validation]="isPaletteCorrect()"
      [readOnly]="!isNewTransfer()"
    />
  }

  @if ((!isNewTransfer() && !isPalette()) || (isNewTransfer() && !inputData()?.palette)) {
    <iu-storekeeper-input
      [type]="'text'"
      [label]="'Product' | SKWTranslate"
      [placeholder]="data()?.M_Product_ID?.displayValue ?? ''"
      [value]="inputData()?.Product?.displayValue ?? ''"
      (valueChange)="changeValue('Product', $event)"
      [validation]="isProductCorrect()"
      [readOnly]="!isNewTransfer()"
    />

    @if (inputData()?.Product) {
      <iu-storekeeper-input
        [type]="'number'"
        [label]="'Quantity' | SKWTranslate"
        [value]="inputData()?.Qty ?? undefined"
        [placeholder]="data()?.Qty ?? ''"
        (valueChange)="changeValue('Qty', $event)"
      />
    }
  }
</div>

<iu-storekeeper-navbar-button
  [icon]="'icon-circle-check'"
  [label]="'Confirm' | SKWTranslate"
  [type]="'confirm'"
  (onClick)="save($event)"
/>

<iu-storekeeper-window-panel
  [title]="'DifferentLocation' | SKWTranslate"
  [(isOpen)]="isOpen"
  (confirm)="save($event, true)"
  (cancel)="cancel($event)"
>
  <i class="icon icon-triangle-exclamation" aria-hidden="true" icon></i>
  <p class="text" content> {{ 'DifferentLocationMsg' | SKWTranslate }}</p>
</iu-storekeeper-window-panel>
