import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, effect, inject, viewChild } from '@angular/core';
import { SKWListTabType, SKWPage, SKWTaskStatus, SKWTransferHeaderData } from '../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../pipes/storekeeper-window-translate.pipe';
import { SKWContextService } from '../../services/storekeeper-window-context.service';
import { SKWNavigationService } from '../../services/storekeeper-window-navigation.service';
import { SKWVirtualScrollDirective } from '../../utils/storekeeper-window-virtual-scroll.directive';
import { StorekeeperEmptyDataComponent } from '../storekeeper-empty-data/storekeeper-empty-data.component';
import { StorekeeperInventoryComponent } from '../storekeeper-inventory/storekeeper-inventory.component';
import { StorekeeperLoadingComponent } from '../storekeeper-loading/storekeeper-loading.component';
import { StorekeeperNavbarButtonComponent } from '../storekeeper-navbar/storekeeper-navbar-button/storekeeper-navbar-button.component';
import { StorekeeperNavbarComponent } from '../storekeeper-navbar/storekeeper-navbar.component';
import { StorekeeperTransferComponent } from '../storekeeper-transfer/storekeeper-transfer.component';
import { StorekeeperWindowCardComponent } from './storekeeper-window-card/storekeeper-window-card.component';
import { SKWListFilterPipe } from './storekeeper-window-list-filter.pipe';

@Component({
  selector: 'iu-storekeeper-window-list',
  standalone: true,
  imports: [
    StorekeeperWindowCardComponent,
    StorekeeperEmptyDataComponent,
    StorekeeperLoadingComponent,
    StorekeeperInventoryComponent,
    StorekeeperTransferComponent,
    StorekeeperNavbarComponent,
    StorekeeperNavbarButtonComponent,
    NgClass,
    SKWListFilterPipe,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    SKWTranslatePipe
  ],
  templateUrl: './storekeeper-window-list.component.html',
  styleUrl: './storekeeper-window-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorekeeperWindowListComponent extends SKWVirtualScrollDirective {
  #SKWContextService = inject(SKWContextService);
  #SKWNavigationService = inject(SKWNavigationService);

  viewport = viewChild(CdkVirtualScrollViewport);

  state = this.#SKWContextService.state;
  page = this.#SKWNavigationService.page;

  tabActive = this.#SKWNavigationService.listTabActive;
  statusTabActive = this.#SKWNavigationService.listStatusTabActive;

  @HostBinding('class.tasks-list')
  get _isTasksList() {
    return this.tabActive() === 'tasks';
  }

  constructor() {
    super();
    effect(() => {
      this.listen(this.viewport()?.elementScrolled());
    });
  }

  override fetchMoreData() {
    const params = this.state.params();

    if (
      (this.#SKWNavigationService.listTabActive() === SKWListTabType.TRANSFERS && params.transfers?.endRow !== -1) ||
      (this.#SKWNavigationService.listTabActive() === SKWListTabType.TASKS &&
        params.tasks[this.#SKWNavigationService.listStatusTabActive()]?.endRow !== -1)
    ) {
      return;
    }

    this.#SKWContextService.addMoreData();
  }

  goToNewTransfer() {
    this.#SKWNavigationService.transferActive.set(undefined);
    this.changePage(SKWPage.TRANSFER);
  }

  changeTabActive(event: MouseEvent, tab: SKWListTabType) {
    this.#SKWNavigationService.changeListTabActive(tab);
    this.#SKWContextService.refresh();
  }

  changeListDisplayTasksDone(event: MouseEvent, value: SKWTaskStatus) {
    this.#SKWNavigationService.changeListStatusTabActive(value);
    this.#SKWContextService.refresh();
  }

  changePage(page: SKWPage) {
    this.#SKWNavigationService.changePage(page);
  }

  trackByFn(index: number, item: SKWTransferHeaderData) {
    return item.Record_ID;
  }
}
