import { NgClass } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { SKWListTabType, SKWPage } from '../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../pipes/storekeeper-window-translate.pipe';
import { SKWNavigationService } from '../../services/storekeeper-window-navigation.service';
import { StorekeeperButtonComponent } from '../storekeeper-button/storekeeper-button.component';

@Component({
  selector: 'iu-storekeeper-empty-data',
  standalone: true,
  imports: [NgClass, StorekeeperButtonComponent, SKWTranslatePipe],
  templateUrl: './storekeeper-empty-data.component.html',
  styleUrl: './storekeeper-empty-data.component.scss'
})
export class StorekeeperEmptyDataComponent {
  #SKWNavigationService = inject(SKWNavigationService);

  icon = input<string>();
  text = input<string>();

  showButton = input(false);

  backToTasks(event: MouseEvent) {
    this.#SKWNavigationService.changeListTabActive(SKWListTabType.TASKS);
    this.#SKWNavigationService.changePage(SKWPage.LIST);
  }
}
