import { ChangeDetectionStrategy, Component, computed, inject, model, OnInit } from '@angular/core';
import { map, tap } from 'rxjs';
import {
  SKWLineStatus,
  SKWStateActionType,
  SKWTransferLineData,
  SKWTransferLineFormData
} from '../../../models/storekeeper-window.model';
import { SKWTranslatePipe } from '../../../pipes/storekeeper-window-translate.pipe';
import { SKWContextService } from '../../../services/storekeeper-window-context.service';
import { SKWMessageService, SKWMessageType } from '../../../services/storekeeper-window-message.service';
import { SKWNavigationService } from '../../../services/storekeeper-window-navigation.service';
import { SKWTransferService } from '../../../services/storekeeper-window-transfer.service';
import { SKWDataService } from '../../../services/strokeeper-window-data.service';
import { StorekeeperButtonComponent } from '../../storekeeper-button/storekeeper-button.component';
import { StorekeeperInputComponent } from '../../storekeeper-input/storekeeper-input.component';
import { StorekeeperNavbarButtonComponent } from '../../storekeeper-navbar/storekeeper-navbar-button/storekeeper-navbar-button.component';
import { StorekeeperWindowInputScanComponent } from '../../storekeeper-window-input-scan/storekeeper-window-input-scan.component';
import { StorekeeperWindowPanelComponent } from '../../storekeeper-window-panel/storekeeper-window-panel.component';
import { StorekeeperTransferDetailsComponent } from '../storekeeper-transfer-details/storekeeper-transfer-details.component';

@Component({
  selector: 'iu-storekeeper-transfer-line-details',
  standalone: true,
  imports: [
    StorekeeperInputComponent,
    StorekeeperButtonComponent,
    StorekeeperTransferDetailsComponent,
    StorekeeperNavbarButtonComponent,
    StorekeeperWindowPanelComponent,
    StorekeeperWindowInputScanComponent,
    SKWTranslatePipe
  ],
  templateUrl: './storekeeper-transfer-line-details.component.html',
  styleUrl: './storekeeper-transfer-line-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StorekeeperTransferLineDetailsComponent implements OnInit {
  readonly #SKWNavigationService = inject(SKWNavigationService);
  readonly #SKWContextService = inject(SKWContextService);
  readonly #SKWDataService = inject(SKWDataService);
  readonly #SKWMessageService = inject(SKWMessageService);
  readonly #SKWTransferService = inject(SKWTransferService);

  headerData = this.#SKWTransferService.data;

  data = model<SKWTransferLineData>(this.#SKWTransferService.selectedLine());
  tabType = this.#SKWNavigationService.detailTabActive;
  isNewTransfer = this.#SKWTransferService.isNewTransfer;

  // TODO: this should be rename to better match utility
  isPalette = computed(
    () => this.#SKWTransferService.isPalette() || (this.isNewTransfer() && this.inputData()?.palette) || this.#isConditioning()
  );

  inputData = model<SKWTransferLineFormData>({
    locator: undefined,
    palette: undefined,
    Product: undefined,
    Qty: undefined,
    ...this.#SKWTransferService.searchData()
  });

  isLocatorCorrect = computed(() => {
    if (this.tabType() === SKWLineStatus.TL) {
      return (
        (!this.data()?.locator_source && this.inputData()?.locator !== undefined) ||
        (this.data()?.locator_source !== undefined && this.inputData()?.locator?.id === this.data()?.locator_source?.id)
      );
    }

    return (
      (this.data()?.locator_destination === undefined && this.inputData()?.locator !== undefined) ||
      (this.data()?.locator_destination !== undefined && this.inputData()?.locator?.id === this.data()?.locator_destination?.id)
    );
  });

  isPaletteCorrect = computed(() => {
    if (this.isNewTransfer()) {
      return this.inputData()?.palette !== undefined;
    }

    return (
      this.inputData()?.palette?.displayValue !== undefined &&
      (this.#isConditioning() ? this.data()?.palette_destination_barcode : this.data()?.palette_source_barcode) ===
        this.inputData()?.palette?.displayValue
    );
  });

  isProductCorrect = computed(() => {
    if (this.data()?.M_Product_ID !== undefined) {
      return this.inputData()?.Product?.id === this.data()?.M_Product_ID?.id;
    }

    return this.inputData()?.Product !== undefined;
  });

  isOpen = model(false);

  ngOnInit(): void {
    this.#SKWTransferService.searchData.set({});
  }

  cancelNewLine(event: MouseEvent) {
    this.#SKWTransferService.selectLine(undefined);
  }

  save(event: MouseEvent, skip = false) {
    if (!this.isPalette() && !this.inputData()?.Qty) {
      this.#SKWMessageService.addMessage({
        type: SKWMessageType.ERROR,
        key: 'SaveTransferNoQtyError',
        title: 'MissingData',
        content: 'MissingDataNoQty'
      });
      return;
    }

    if (!skip && !this.#isConditioning() && !this.isLocatorCorrect()) {
      this.isOpen.set(true);
      return;
    }

    if (this.isNewTransfer() || !this.data()?.Line_ID) {
      this.#SKWTransferService.save(this.headerData(), this.inputData(), this.isPalette());
      return;
    }

    this.#updateLine();
  }

  #updateLine() {
    if (!this.isPalette() && !this.isProductCorrect()) {
      this.#SKWMessageService.addMessage({
        type: SKWMessageType.ERROR,
        key: 'updateTransferLineError',
        title: 'ProductError',
        content: 'ProductErrorMsg'
      });
      return;
    }

    if (this.isPalette() && !this.isPaletteCorrect()) {
      this.#SKWMessageService.addMessage({
        type: SKWMessageType.ERROR,
        key: 'updateTransferLineError',
        title: 'PalletError',
        content: 'PalletErrorMsg'
      });
      return;
    }

    this.#SKWContextService.newAction({
      type: SKWStateActionType.SAVE,
      isLoading: true,
      clearData: false,
      source: this.#SKWDataService.updateTransferLine(this.data(), this.inputData()).pipe(
        tap((result) => {
          if (result?.error) {
            this.#SKWMessageService.addMessage({
              type: SKWMessageType.ERROR,
              key: 'updateTransferLineError',
              title: 'SaveTransferLineError',
              content: result.error
            });
            return;
          }

          if (result?.data) {
            this.headerData.update((d) => ({ ...d, nblineco: (d.nblineco ?? 0) + 1 }));
            this.data.set(result.data);
            this.#SKWTransferService.selectLine(undefined);
          }
        }),
        map(() => {
          return {
            data: {
              ...this.#SKWContextService.state.data(),
              tasks: {
                AS: this.#SKWContextService.state.data().tasks.AS.map((task) => {
                  if (task.Record_ID === this.headerData().Record_ID) {
                    task = {
                      ...task,
                      nblineco: (task.nblineco ?? 0) + 1
                    };
                  }

                  return task;
                }),
                CO: this.#SKWContextService.state.data().tasks?.CO ?? []
              }
            }
          };
        })
      )
    });
  }

  cancel(event: MouseEvent) {
    this.isOpen.set(false);
  }

  changeValue(key: keyof SKWTransferLineFormData, value: unknown) {
    if (this.inputData()[key] === value) return;
    this.inputData.update((d) => ({ ...d, [key]: value }));
  }

  #isConditioning() {
    return this.data()?.DocStatus === SKWLineStatus.UL && !!this.data()?.M_Product_ID && !!this.data()?.palette_destination;
  }
}
